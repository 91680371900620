import { useState } from "react";
import { useNavigate } from "react-router";
import Topbar from "../../../components/Topbar";

// import AddAvatar from "./AddAvatar";
import AvatarList from "./AvatarList";

/**
 * Contains all Avatar related components
 * @returns
 */
export default function AvatarManagement(): JSX.Element {
  let navigator = useNavigate()
  const [search, setSearch] = useState("");

  function handleSearch(search: string) {
    setSearch(search)
  }

  return (
    <div className="avatar-management">
      <Topbar
        title="Avatar List"
        buttonTitle="Add Avatar"
        handleButton={() => navigator("/addavatar")}
        searchbar
        handleSearch={handleSearch}
      />
      <AvatarList search={search} />
    </div>
  );
}
