import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  TextField,
  Typography,
  Snackbar,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  SelectChangeEvent,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import SelectableButton from "../../components/SelectableButton";
import {
  getUserAvatarList,
  getUserEmoteList,
  getUserAvatarBackgroundList,
  getPublicAvatars,
  getPublicEmotes,
  getPublicAvatarBackgrounds,
  getAvatarSpecificBackgroundList,
  getAvatarSpecificEmoteList,
  sendFeatureRequest,
  getStreamingPublicAvatars,
  getStreamingObtainedAvatars,
  getStreamingPersonalAvatars,
} from "../../utility/Endpoints";
import Get from "../../utility/Get";
import Preview from "./components/Preview";
import {
  Avatar,
  AvatarAsset,
  BackgroundType,
  Emote,
} from "../../utility/Types";
import { useNavigate } from "react-router";
import _ from "lodash";
import Post from "../../utility/Post";
import Microphone from "./components/Microphone";

type StreamType = {
  avatar: Avatar;
  background: BackgroundType;
  emotes: Array<Emote>;
  showEmotes: boolean | null;
};

/**
 * Preview and select avatars, emotes, and backgrounds
 * @returns
 */
export default function Dashboard(): JSX.Element {
  const [selectedAvatar, setSelectedAvatar] = useState<Avatar>();
  const [selectedEmotes, setSelectedEmotes] = useState<Array<Emote>>([]);
  const [selectedBackground, setSelectedBackground] =
    useState<BackgroundType | null>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorDetail, setErrorDetail] = useState<string>("");

  const [avatarList, setAvatarList] = useState<Array<Avatar> | null>(null);
  const [backgroundList, setBackgroundList] =
    useState<Array<BackgroundType> | null>(null);
  const [specificBackgroundList, setSpecificBackgroundList] =
    useState<Array<BackgroundType> | null>(null);
  const [emoteList, setEmoteList] = useState<Array<Emote> | null>(null);
  const [specificEmoteList, setSpecificEmoteList] =
    useState<Array<Emote> | null>(null);

  const [isShowEmotesChecked, setShowEmotesChecked] = useState<boolean>(true);
  const [error, setError] = useState<{
    avatars: boolean;
    emotes: boolean;
    backgrounds: boolean;
  }>({ avatars: false, emotes: false, backgrounds: false });

  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const openSnackbar = () => setShowSnackbar(true);
  const closeSnackbar = () => setShowSnackbar(false);

  const [snackbarMessage, setSnackbarMessage] = useState<JSX.Element>(
    <Alert></Alert>
  );

  const [isReqFeatOpen, setReqFeatOpen] = useState<boolean>(false);
  const openReqFeat = () => setReqFeatOpen(true);
  const closeReqFeat = () => setReqFeatOpen(false);
  const [reqService, setReqService] = useState<string>("public");
  const [reqFeat, setReqFeat] = useState<string>("Feature");
  const [reqFeatBody, setReqFeatBody] = useState<string>("");

  // const handleBackgroundSubmit = () => {
  //   const pk: number = JSON.parse(
  //     localStorage.getItem("vstreamer_user") as string
  //   ).pk as number;

  //   if (
  //     backgroundFormData.name === "" ||
  //     backgroundFormData.image === undefined
  //   ) {
  //     if (backgroundFormData.name === "") {
  //       setBackgroundFormErrors((prev) => ({ ...prev, name: true }));
  //     }
  //     if (backgroundFormData.image === undefined) {
  //       setBackgroundFormErrors((prev) => ({ ...prev, image: true }));
  //     }
  //   } else {
  //     const formData = new FormData();
  //     formData.append("name", backgroundFormData.name);
  //     formData.append("image", backgroundFormData.image as Blob);
  //     formData.append("is_public", "false");

  //     Post(userAvatarBackgroundCreate(pk), formData).then((res) => {
  //       if (res && res.status < 300) {
  //         const connectionFormData = new FormData();
  //         connectionFormData.append("user", pk.toString());
  //         Post(
  //           createBackgroundUserConnection(res.data.data.pk),
  //           connectionFormData
  //         ).then((res) => {
  //           if (res && res.status < 300) {
  //             // Append to backgroundList
  //             setBackgroundList((prev) => {
  //               if (prev !== null)
  //                 return [...prev, res.data.data.avatar_background];
  //               else return [res.data.data.avatar_background];
  //             });
  //             setSnackbarMessage(
  //               <Alert severity="success">Background uploaded!</Alert>
  //             );
  //             openSnackbar();
  //             closeModal();
  //           } else if (res && res.status === 401) {
  //             navigator("/login");
  //           } else {
  //             openSnackbar();
  //             setSnackbarMessage(
  //               <Alert severity="error">
  //                 "Something went wrong! Please try refreshing."
  //               </Alert>
  //             );
  //           }
  //         });
  //       } else if (res && res.status === 401) {
  //         navigator("/login");
  //       } else {
  //         openSnackbar();
  //         setSnackbarMessage(
  //           <Alert severity="error">"Unable to upload background."</Alert>
  //         );
  //       }
  //     });
  //   }
  // };
  const [threshold, setThreshold] = useState<number>(0.23);

  const handleSelectAvatar = (item: Avatar) => {
    setSelectedEmotes([]);
    setSelectedAvatar(item);
    getSpecificBackgrounds(item);
    getSpecificEmotes(item);
  };

  const handleSelectEmote = (item: Emote) => {
    if (
      selectedEmotes.length < 10 ||
      selectedEmotes.find((x) => x.pk === item.pk)
    )
      selectedEmotes.find((x) => x.pk === item.pk)
        ? setSelectedEmotes((prev) => prev.filter((cur) => cur.pk !== item.pk))
        : setSelectedEmotes((prev) => [...prev, item]);
  };

  const handleSelectBackground = (item: BackgroundType) => {
    if (item === selectedBackground) {
      setSelectedBackground(null);
    } else {
      setSelectedBackground(item);
    }
  };

  const [prevSession, setPrevSession] = useState<StreamType>();

  const checkAvatar = useCallback(
    (item) => {
      if (selectedAvatar) {
        return selectedAvatar.pk === item.pk;
      }
      return false;
    },
    [selectedAvatar]
  );

  const checkBackground = useCallback(
    (item) => {
      if (selectedBackground) {
        return selectedBackground.pk === item.pk;
      }
      return false;
    },
    [selectedBackground]
  );

  const checkEmote = useCallback(
    (item) => {
      return selectedEmotes.find((x) => x.pk === item.pk) ? true : false;
    },
    [selectedEmotes]
  );

  const checkEmoteIndex = useCallback(
    (item) => {
      return selectedEmotes.findIndex((x) => x.pk === item.pk);
    },
    [selectedEmotes]
  );

  let navigator = useNavigate();

  const width: number = 500 + 64;
  const height: number = 500 + 80;

  const newWindowOptions: string = `fullscreen=no,height=${height},left=0,location=no,menubar=no,resizable=no,scrollbars=no,status=no,titlebar=no,toolbar=no,top=0,width=${width}`;

  useEffect(() => {
    if (prevSession === undefined) {
      if (localStorage.getItem("avatar-stream") !== null) {
        setPrevSession(
          JSON.parse(localStorage.getItem("avatar-stream") as string)
        );
      } else {
        localStorage.setItem("avatar-stream", "{}");
      }
    } else {
      if (prevSession && prevSession.avatar) {
        setSelectedAvatar(prevSession.avatar);
        getSpecificBackgrounds(prevSession.avatar);
        getSpecificEmotes(prevSession.avatar);
      }

      if (prevSession && prevSession.emotes) {
        setSelectedEmotes(prevSession.emotes);
      }

      if (prevSession && prevSession.background) {
        setSelectedBackground(prevSession.background);
      }

      if (prevSession && prevSession.showEmotes) {
        setShowEmotesChecked(prevSession.showEmotes);
      }
    }
    // eslint-disable-next-line
  }, [prevSession]);

  // Get the list of user Avatars, Emotes, and Backgrounds
  useEffect(() => {
    let avatarStream = JSON.parse(
      localStorage.getItem("avatar-stream") as string
    );
    if (Object.keys(avatarStream).includes("threshold")) {
      setThreshold(avatarStream.threshold);
    }

    if (localStorage.getItem("vstreamer_user")) {
      const pk: number = JSON.parse(
        localStorage.getItem("vstreamer_user") as string
      ).pk as number;

      //get public objects first and then user specific objects
      var organization = { pk: 0 };
      if (localStorage.getItem("organization")) {
        organization = JSON.parse(localStorage.getItem("organization") ?? "");
      }
      Get(getStreamingPublicAvatars()).then((res) => {
        if (res.status && res.status < 300) {
          if (res.data && res.data.data) {
            Get(getUserAvatarList(pk)).then((res1) => {
              if (res1.status && res1.status < 300) {
                if (res1.data && res1.data.data) {
                  Get(getStreamingObtainedAvatars()).then((res2) => {
                    if (res2.status && res2.status < 300) {
                      if (res2.data && res2.data.data) {
                        Get(getStreamingPersonalAvatars()).then((res3) => {
                          if (res3.status && res3.status < 300) {
                            if (res3.data && res3.data.data) {
                              setAvatarList(() => {
                                var temp01 = _.uniqWith(
                                  res.data.data.concat(res1.data.data),
                                  _.isEqual
                                )
                                var temp23 = _.uniqWith(
                                  res2.data.data.concat(res3.data.data),
                                  _.isEqual
                                )
                                return _.uniqWith(
                                  temp01.concat(temp23),
                                  _.isEqual
                                );
                              });
                              setError((prev) => ({ ...prev, avatars: false }));
                            }
                          } else if (res3.status === 401) {
                            navigator("/login");
                          } else {
                            setError((prev) => ({ ...prev, avatars: true }));
                          }
                        });
                        setError((prev) => ({ ...prev, avatars: false }));
                      }
                    } else if (res2.status === 401) {
                      navigator("/login");
                    } else {
                      setError((prev) => ({ ...prev, avatars: true }));
                    }
                  });
                  setError((prev) => ({ ...prev, avatars: false }));
                }
              } else if (res1.status === 401) {
                navigator("/login");
              } else {
                setError((prev) => ({ ...prev, avatars: true }));
              }
            });
          }
        } else if (res.status === 401) {
          navigator("/login");
        } else {
          setError((prev) => ({ ...prev, avatars: true }));
        }
      });

      Get(getPublicEmotes(organization.pk)).then((res) => {
        if (res.status && res.status < 300) {
          if (res.data && res.data.data) {
            Get(getUserEmoteList(pk)).then((res1) => {
              if (res1.status && res1.status < 300) {
                if (res1.data && res1.data.data) {
                  setEmoteList(() => {
                    return _.uniqWith(
                      res.data.data.concat(res1.data.data),
                      _.isEqual
                    );
                  });
                  setError((prev) => ({ ...prev, emotes: false }));
                }
              } else if (res1.status === 401) {
                navigator("/login");
              } else {
                setError((prev) => ({ ...prev, emotes: true }));
              }
            });
          }
        } else if (res.status === 401) {
          navigator("/login");
        } else {
          setError((prev) => ({ ...prev, emotes: true }));
        }
      });

      Get(getPublicAvatarBackgrounds(organization.pk)).then((res) => {
        if (res.status && res.status < 300) {
          if (res.data && res.data.data) {
            Get(getUserAvatarBackgroundList(pk)).then((res1) => {
              if (res1.status && res1.status < 300) {
                if (res1.data && res1.data.data) {
                  setBackgroundList(() => {
                    return _.uniqWith(
                      res.data.data.concat(res1.data.data),
                      _.isEqual
                    );
                  });
                  setError((prev) => ({ ...prev, backgrounds: false }));
                }
              } else if (res1.status === 401) {
                navigator("/login");
              } else {
                setError((prev) => ({ ...prev, backgrounds: true }));
              }
            });
          }
        } else if (res.status === 401) {
          navigator("/login");
        } else {
          setError((prev) => ({ ...prev, backgrounds: true }));
        }
      });
    }
  }, [navigator]);

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
  }, [showError]);

  function getSpecificBackgrounds(avatar: Avatar) {
    //check if selected background is in current specific background list
    //if so, we need to remove it
    if (
      selectedBackground &&
      specificBackgroundList?.findIndex(
        (e: BackgroundType) => e.pk === selectedBackground.pk
      ) !== undefined &&
      specificBackgroundList?.findIndex(
        (e: BackgroundType) => e.pk === selectedBackground.pk
      ) >= 0
    ) {
      setSelectedBackground(null);
    }
    //then get new list
    Get(getAvatarSpecificBackgroundList(avatar.pk)).then((val) => {
      if (val.status && val.status < 300) {
        setSpecificBackgroundList(val.data.data);
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    });
  }

  function getSpecificEmotes(avatar: Avatar) {
    //dont need to check last emotes because we clear all emotes when the selected avatar changes
    //get new list
    Get(getAvatarSpecificEmoteList(avatar.pk)).then((val) => {
      if (val.status && val.status < 300) {
        setSpecificEmoteList(val.data.data);
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    });
  }

  const handleStartStreaming = () => {
    localStorage.removeItem("avatar-stream");

    if (selectedAvatar) {
      // Set avatar
      let data = {};
      data = { ...data, avatar: selectedAvatar };

      // Set background
      data = { ...data, background: selectedBackground };

      // Set emotes
      data = { ...data, emotes: selectedEmotes };

      // Set show emotes
      data = { ...data, showEmotes: isShowEmotesChecked };

      // Set microphone threshold
      data = { ...data, threshold };

      localStorage.setItem("avatar-stream", JSON.stringify(data));
      window.open("/display", "_blank", newWindowOptions);
    } else {
      setErrorDetail("You must select at least 1 avatar");
      setShowError(true);
    }
  };

  const handleCheckbox = () => {
    setShowEmotesChecked((prev) => !prev);
  };

  function featureRequest() {
    const formDataCreate = new FormData();
    formDataCreate.append("service_type", reqService);
    formDataCreate.append("feature_type", reqFeat);
    formDataCreate.append("message_body", reqFeatBody);
    Post(sendFeatureRequest(), formDataCreate).then((val) => {
      if (val.status && val.status < 300) {
        openSnackbar();
        setSnackbarMessage(
          <Alert severity="info">"Feature request sent!"</Alert>
        );
      } else {
        if (val.status === 401) {
          navigator("/login");
        } else {
          //handle errors
          var temp = Object.keys(val.data.form.errors)[0];
          openSnackbar();
          setSnackbarMessage(
            temp ? (
              <Alert severity="error">{temp}</Alert>
            ) : (
              <Alert severity="error">
                "Unable to send request now. Try again later."
              </Alert>
            )
          );
        }
      }
      closeReqFeat();
    });
  }

  return (
    <Box className="dashboard">
      <Grid container spacing={2}>
        <Grid item textAlign="center" xs={12} sm={6} md={4}>
          <Grid item xs={12}>
            <Typography>Avatars</Typography>
          </Grid>
          <Grid
            className="user-component"
            container
            item
            xs={12}
            maxHeight="40%"
            overflow="auto"
            paddingY="1rem"
          >
            {avatarList && avatarList.length > 0 ? (
              avatarList.map((item) => {
                return (
                  <Grid item xs={12} sm={6} md={6} lg={4} key={item.pk}>
                    <SelectableButton
                      selected={checkAvatar(item)}
                      onClick={() => {
                        handleSelectAvatar(item);
                      }}
                      height="auto"
                    >
                      <img
                        className="preview-image"
                        src={item.image}
                        alt={`${item.name} avatar`}
                        style={{ width: "100%" }}
                      />
                      <p style={{ wordBreak: "break-all", padding: "0 .4rem" }}>
                        {item.name}
                      </p>
                    </SelectableButton>
                  </Grid>
                );
              })
            ) : (
              <div
                style={{
                  paddingLeft: ".4rem",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {error.avatars
                  ? "Avatars failed to load. Please refresh or contact an admin if this continues."
                  : "You don't have any avatars."}
              </div>
            )}
          </Grid>

          <Grid item xs={12} marginTop="1rem">
            <Typography>Emotes</Typography>
          </Grid>
          <Grid
            className="user-component"
            container
            item
            xs={12}
            maxHeight="40%"
            overflow="auto"
            paddingY="1rem"
          >
            {specificEmoteList &&
              specificEmoteList.length > 0 &&
              // eslint-disable-next-line
              specificEmoteList.map((item) => {
                if (
                  item.asset === null ||
                  (selectedAvatar &&
                    selectedAvatar.avatar_assets !== null &&
                    !!selectedAvatar.avatar_assets.find(
                      (x: AvatarAsset) => x.asset.pk === item.asset.pk
                    ))
                ) {
                  return (
                    <Grid item xs={12} sm={6} md={6} lg={4} key={item.pk}>
                      <SelectableButton
                        selected={checkEmote(item)}
                        onClick={() => {
                          handleSelectEmote(item);
                        }}
                        height="auto"
                        num={checkEmoteIndex(item)}
                      >
                        <img
                          className="preview-image"
                          src={item.thumbnail_image}
                          alt={`${item.name} emote`}
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{ wordBreak: "break-all", padding: "0 .4rem" }}
                        >
                          {item.name}
                        </p>
                      </SelectableButton>
                    </Grid>
                  );
                }
              })}
            {emoteList && emoteList.length > 0 ? (
              // eslint-disable-next-line
              emoteList.map((item) => {
                if (
                  item.asset === null ||
                  (selectedAvatar &&
                    selectedAvatar.avatar_assets !== null &&
                    !!selectedAvatar.avatar_assets.find(
                      (x: AvatarAsset) => x.asset.pk === item.asset.pk
                    ))
                ) {
                  return (
                    <Grid item xs={12} sm={6} md={6} lg={4} key={item.pk}>
                      <SelectableButton
                        selected={checkEmote(item)}
                        onClick={() => {
                          handleSelectEmote(item);
                        }}
                        height="auto"
                        num={checkEmoteIndex(item)}
                      >
                        <img
                          className="preview-image"
                          src={item.thumbnail_image}
                          alt={`${item.name} emote`}
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{ wordBreak: "break-all", padding: "0 .4rem" }}
                        >
                          {item.name}
                        </p>
                      </SelectableButton>
                    </Grid>
                  );
                }
              })
            ) : (
              <div
                style={{
                  paddingLeft: ".4rem",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {error.emotes
                  ? "Emotes failed to load. Please refresh or contact an admin if this continues."
                  : "You don't have any emotes."}
              </div>
            )}
          </Grid>
        </Grid>

        <Grid item textAlign="center" xs={12} sm={6} md={4}>
          {showError && (
            <Grid
              item
              xs={12}
              position="fixed"
              bottom="2rem"
              left="2rem"
              sx={{ zIndex: "10000000000000" }}
            >
              <Alert
                variant="filled"
                severity="error"
                onClick={() => setShowError(false)}
                sx={{ zIndex: "10000000000000" }}
              >
                {errorDetail}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography>Preview</Typography>
          </Grid>
          <Grid item xs={12} height="55%">
            <Preview avatar={selectedAvatar} background={selectedBackground} />
          </Grid>
          <Grid container item xs={12} marginTop="1rem">
            <Grid item xs={12}>
              <Button variant="contained" onClick={handleStartStreaming}>
                <Typography>Start Streaming</Typography>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={handleCheckbox}
                    checked={isShowEmotesChecked}
                  />
                }
                label="Show Emotes"
                sx={{
                  position: { xs: "relative" },
                  marginLeft: "1rem",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Microphone
                isUsingModal
                showThreshold
                setThreshold={setThreshold}
                threshold={threshold}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          textAlign="center"
          xs={12}
          sm={6}
          md={4}
          sx={{ marginTop: { xs: "1rem", md: 0 } }}
        >
          <Grid item xs={12}>
            <Typography>Backgrounds</Typography>
          </Grid>
          <Grid
            className="user-component"
            container
            item
            xs={12}
            maxHeight="40%"
            overflow="auto"
            paddingY="1rem"
          >
            {/* <Grid item xs={12} sm={6} md={6} lg={4} key="add-bg">
              <SelectableButton
                selected={false}
                onClick={() => {
                  openModal();
                }}
                height="auto"
              >
                <div className="user-component__image">
                  <div className="preview-image">
                    <AddIcon
                      fontSize="large"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </div>
                <p style={{ wordBreak: "normal" }}>Add Custom Background</p>
              </SelectableButton>
              </Grid> */}
            {specificBackgroundList &&
              specificBackgroundList.length > 0 &&
              specificBackgroundList.map((item) => {
                return (
                  <Grid item xs={12} sm={6} md={6} lg={4} key={item.pk}>
                    <SelectableButton
                      selected={checkBackground(item)}
                      onClick={() => {
                        handleSelectBackground(item);
                      }}
                      height="auto"
                    >
                      <div className="user-component__image">
                        <img
                          className="preview-image"
                          src={item.image}
                          alt={`${item.name} background`}
                        />
                      </div>
                      <p style={{ wordBreak: "break-all" }}>{item.name}</p>
                    </SelectableButton>
                  </Grid>
                );
              })}
            {backgroundList && backgroundList.length > 0 ? (
              backgroundList.map((item) => {
                return (
                  <Grid item xs={12} sm={6} md={6} lg={4} key={item.pk}>
                    <SelectableButton
                      selected={checkBackground(item)}
                      onClick={() => {
                        handleSelectBackground(item);
                      }}
                      height="auto"
                    >
                      <div className="user-component__image">
                        {["mov", "mp4"].includes(
                          item.image.split(".")[
                            item.image.split(".").length - 1
                          ]
                        ) ? (
                          <video loop autoPlay muted src={item.image} />
                        ) : (
                          <img
                            className="preview-image"
                            src={item.image}
                            alt={`${item.name} background`}
                          />
                        )}
                      </div>
                      <p style={{ wordBreak: "break-all" }}>{item.name}</p>
                    </SelectableButton>
                  </Grid>
                );
              })
            ) : (
              <div
                style={{
                  paddingLeft: ".4rem",
                  padding: "0 .4rem",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {error.backgrounds
                  ? "Backgrounds failed to load. Please refresh or contact an admin if this continues."
                  : "You don't have any backgrounds."}
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Box component="div">
        <Button
          variant="contained"
          sx={{ marginTop: "1rem" }}
          onClick={() => {
            openReqFeat();
          }}
        >
          Request A Feature
        </Button>
      </Box>

      {/* <Modal open={open} onClose={closeModal}>
        <Box
          component="form"
          onSubmit={handleBackgroundSubmit}
          style={{
            backgroundColor: "white",
            padding: "2rem",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ textAlign: "center" }}>
            Add Custom Background
          </Typography>
          <TextField
            name="name"
            label="Name"
            value={backgroundFormData.name}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setBackgroundFormData((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
            sx={{ margin: ".4rem 0" }}
            required
            error={backgroundFormErrors.name}
            helperText={backgroundFormErrors.name ? "Please input a name." : ""}
          />
          <Button component="label" variant="outlined">
            <FileUploadIcon />
            {backgroundFormData.image === undefined
              ? " Background Image"
              : backgroundFormData.image.name}
            <input
              required
              type="file"
              hidden
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setBackgroundFormData((prev) => ({
                  ...prev,
                  image:
                    e.target.files !== null ? e.target.files[0] : undefined,
                }))
              }
            />
          </Button>
          {backgroundFormErrors.image && (
            <span
              style={{
                color: "#f03434",
                fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                fontWeight: "400",
                fontSize: " 0.75rem",
                lineHeight: "1.66",
                letterSpacing: "0.03333em",
                textAlign: "left",
                marginTop: "3px",
                marginRight: "14px",
                marginBottom: "0",
                marginLeft: "14px",
              }}
            >
              Please select a background image.
            </span>
          )}
          <Button
            onClick={handleBackgroundSubmit}
            sx={{ marginTop: "1.2rem", marginBottom: ".4rem" }}
            variant="contained"
          >
            Add Background
          </Button>
          <Button
            onClick={() => closeModal()}
            color="error"
            variant="contained"
          >
            Close
          </Button>
        </Box>
      </Modal> */}

      <Modal open={isReqFeatOpen} onClose={closeReqFeat}>
        <Box
          component="form"
          sx={{ background: "white", padding: "2rem", borderRadius: "10px" }}
        >
          <Typography variant="h5" mb={2}>
            Request a Feature
          </Typography>
          <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
            <FormLabel id="request-row-radio-buttons-group-label">
              Service Type
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="request-radio-buttons-group-label"
              defaultValue="public"
              name="radio-buttons-group"
              value={reqService}
              onChange={(e) => setReqService(e.target.value)}
            >
              <FormControlLabel
                value="public"
                control={<Radio />}
                label="Public"
              />
              <FormControlLabel
                value="commission"
                control={<Radio />}
                label="Commission"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="email-feature">Feature Type</InputLabel>
            <Select
              labelId="email-feature"
              label="Feature Type"
              value={reqFeat}
              onChange={(e: SelectChangeEvent<string>) => {
                setReqFeat(e.target.value);
              }}
            >
              <MenuItem value="Avatar">Avatar</MenuItem>
              <MenuItem value="Emote">Emote</MenuItem>
              <MenuItem value="Background">Background</MenuItem>
              <MenuItem value="Feature">Feature</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ margin: "1rem 0" }}>
            <TextField
              id="outlined-multiline-static"
              label="Describe your Feature"
              multiline
              rows={4}
              onChange={(e) => {
                setReqFeatBody(e.target.value);
              }}
              value={reqFeatBody}
            />
          </FormControl>
          <FormControl fullWidth>
            <Button
              onClick={() => {
                featureRequest();
              }}
              variant="contained"
              sx={{ marginTop: ".4rem" }}
            >
              Request Feature
            </Button>
            <Button
              onClick={closeReqFeat}
              variant="contained"
              color="error"
              sx={{ marginTop: ".4rem" }}
            >
              <Typography color="white">Cancel</Typography>
            </Button>
          </FormControl>
        </Box>
      </Modal>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={closeSnackbar}
      >
        {snackbarMessage}
      </Snackbar>
    </Box>
  );
}
