import { Navigate, Outlet, useLocation } from "react-router-dom";
import Footer from "../features/Footer/Footer";
import Navigation from "../features/Navigation/Navigation";
import { checkUserAuth } from "./CheckUserAuth";

/**
 * Show the appropriate screens if the user is logged in
 * @returns
 */
export function PrivateRoute(): JSX.Element {
  const auth = checkUserAuth();
  const location = useLocation();
  return auth ? (
    <>
      <Navigation />
      <Outlet />
      {location.pathname !== "/display" && <Footer />}
    </>
  ) : (
    <Navigate to={"/login"} />
  );
}
