import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { createAvatarSpecificBackground, createBackground, getOrgAvatarList } from "../../../utility/Endpoints";
import Post from "../../../utility/Post";
import UploadIcon from "@mui/icons-material/Upload";
import Get from "../../../utility/Get";

const style = {
  backgroundColor: "white",
  padding: "1.5rem",
  borderRadius: "8px",
  width: "15rem",
};

interface AddBackgroundProps {
  isOpen: boolean;
  handleClose(): any;
  infoMessage: (message: string) => void;
  errorMessage: (message: string) => void;
}

/**
 * Add an Background to the database
 * @param props
 * @returns
 */
export default function AddBackground(props: AddBackgroundProps): JSX.Element {
  const [userPK, setUserPk] = useState({ pk: 0 });
  let navigator = useNavigate();
  const [session, setSession] = useState({ 
    name: "", 
    is_public: false, 
    specific_avatar: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    image: "",
  });
  const [avatars, setAvatars] = useState<
    {
      avatar_type: object;
      name: string;
      image: string;
      pk: number;
    }[]
  >([]);

  useEffect(() => {
    var user = { pk: 0 };
    if (localStorage.getItem("vstreamer_user")) {
      user = JSON.parse(localStorage.getItem("vstreamer_user") ?? "");
    }
    setUserPk(user);
    getAvatars()
    // eslint-disable-next-line
  }, []);

  function getAvatars() {
    var organization = { pk: 0 };
    if (localStorage.getItem("organization")) {
      organization = JSON.parse(localStorage.getItem("organization") ?? "");
    }
    Get(getOrgAvatarList(organization.pk)).then((val) => {
      if (val.status && val.status < 300) {
        setAvatars(val.data.data);
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    });
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    var error = false;
    const FormData = require("form-data");
    const formData = new FormData();
    let imageFile = document.getElementById("image_file") as HTMLInputElement;

    if (session.name === "" || session.name.length === 0) {
      setErrors({ ...errors, name: "Name cannot be empty" });
      error = true;
    }
    if (
      imageFile === undefined ||
      imageFile.files === undefined ||
      imageFile.files?.length === 0
    ) {
      setErrors({ ...errors, image: "Image cannot be empty" });
      error = true;
    }

    if (!error) {
      setIsLoading(true);
      formData.append("name", session.name);
      formData.append(
        "image",
        imageFile && imageFile.files ? imageFile.files[0] : ""
      );
      formData.append("is_public", session.is_public)

      Post(createBackground(userPK.pk), formData).then((val) => {
        if (val.status && val.status < 300) {
          props.infoMessage("Background created!");
          //create background and avatar connection
          if(session.specific_avatar > 0) {
            const formDataSpecific = new FormData();
            formDataSpecific.append("background", val.data.data.pk)
            Post(createAvatarSpecificBackground(session.specific_avatar), formDataSpecific).then(val1 => {
              if(val1.status && val1.status < 300) {
                //connection made
              } else {
                if (val.status === 401) {
                  navigator("/login");
                } else {
                  //handle errors
                  var temp = Object.keys(val.data.form.errors)[0];
                  props.errorMessage(val.data.form.errors[temp]);
                }
              }
            })
          }
        } else {
          if (val.status === 401) {
            navigator("/login");
          } else {
            //handle errors
            var temp = Object.keys(val.data.form.errors)[0];
            props.errorMessage(val.data.form.errors[temp]);
          }
        }
        setIsLoading(false);
        props.handleClose();
      });
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSession({ ...session, [e.target.name]: e.target.value });
  }

  function handleCheck(e: React.ChangeEvent<HTMLInputElement>) {
    setSession({ ...session, [e.target.name]: e.target.checked })
  }

  function handleAssetChange(e: SelectChangeEvent<any>): void {
    setSession({ ...session, [e.target.name]: e.target.value });
  }

  return (
    <Modal open={props.isOpen} onClose={props.handleClose}>
      <Box style={style}>
        <FormControl fullWidth>
          <FormLabel>Add Background</FormLabel>
          <TextField
            margin="dense"
            label="Name"
            required
            value={session.name}
            name="name"
            onChange={handleChange}
            error={errors.name !== ""}
            helperText={errors.name}
            disabled={isLoading}
          />

          <FormControl style={{ marginTop: ".4rem" }}>
            <FormLabel>Background</FormLabel>
            <Button variant="outlined" component="label">
              <UploadIcon />
              <input
                className="hide-file-btn"
                style={{ marginTop: "0" }}
                required
                id="image_file"
                name="image"
                type="file"
                disabled={isLoading}
              />
            </Button>
            {errors.image !== "" && (
              <div style={{ color: "#dd8782" }}>{errors.image}</div>
            )}
          </FormControl>
          <FormControl fullWidth style={{ marginTop: ".8rem" }}>
              <InputLabel id="assetSelect">Specific Avatar</InputLabel>
              <Select
                labelId="assetSelect"
                value={session.specific_avatar}
                label="asset"
                name={"specific_avatar"}
                onChange={handleAssetChange}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {avatars &&
                  avatars.map((item) => [
                    <MenuItem value={item.pk} key={item.pk}>
                      {item.name}
                      </MenuItem>
                  ])}
              </Select>
            </FormControl>
            {/* note: if a use sets this ^, the emote will only show up for the selected avatar  */}
            
          <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_public"
                    checked={session.is_public}
                    onChange={handleCheck}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled={isLoading}
                  />
                }
                label="Public"
              />
            </FormGroup>

          <Button
            variant="contained"
            sx={{ my: 1 }}
            type="submit"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            <Typography color="white">Add Background</Typography>
          </Button>
          <Button
            onClick={() => {
              setErrors({
                name: "",
                image: "",
              });
              props.handleClose();
            }}
            variant="contained"
            color="error"
            sx={{ mx: 2 }}
            disabled={isLoading}
          >
            <Typography color="white">Cancel</Typography>
          </Button>
        </FormControl>
      </Box>
    </Modal>
  );
}
