import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

/**
 * Display all Admin paths
 * @returns
 */
export default function AdminNavigation(): JSX.Element {
  const [pages] = useState<Array<{ label: string; path: string }>>([
    { label: "Dashboard", path: "/" },
    { label: "Users", path: "/usermanagement" },
    { label: "Avatars", path: "/avatarmanagement" },
    { label: "Emotes", path: "/emotemanagement" },
    { label: "Backgrounds", path: "/backgroundmanagement" },
    { label: "Account", path: "/account" },
    { label: "FAQ", path: "/faq" },
  ]);
  const [curPage, setPage] = useState<string>("/");
  const { pathname } = useLocation();

  useEffect(() => {
    setPage(pathname);
  }, [pathname]);

  let navigate = useNavigate();

  return (
    <div className="admin-navigation">
      {pages.map((page, key) => {
        return (
          <Button
            onClick={() => navigate(page.path)}
            color={curPage === page.path ? "primary" : "secondary"}
            key={key}
          >
            {page.label}
          </Button>
        );
      })}
    </div>
  );
}
