import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  createAvatarType,
  deleteAvatarType,
  getAvatarAssetsTypes,
  getAvatarTypeList,
  updateAvatarType,
} from "../../../../utility/Endpoints";
import Get from "../../../../utility/Get";
import Post from "../../../../utility/Post";
import EditAssetType from "./EditAssetType";

const style = {
  backgroundColor: "white",
  padding: "1.5rem",
  borderRadius: "8px",
  width: "70vw",
  height: "90vh",
  overflow: "auto",
};

type AvatarTypeObject = {
  pk: number;
  name: string;
  visible: boolean;
  enabled: boolean;
  asset_types?: Array<AssetTypeObject>;
};

type AssetTypeObject = {
  enabled: boolean;
  name: string;
  parent_asset_type_id: number | null;
  pk: number;
  tiltable: boolean;
  visible: boolean;
};

interface EditAvatarTypesProps {
  openEditTypeModal: boolean;
  setOpenEditTypeModal: (modalState: boolean) => void;
  //handle updating parent with new types
  updateAvatarTypeList: (typeList: Array<AvatarTypeObject>) => void;
}

export default function EditAvatarType(
  props: EditAvatarTypesProps
): JSX.Element {
  let navigator = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [editAssetTypesModal, setEditAssetTypesModal] = useState(false);
  const [selectedAvatarType, setSelectedAvatarType] =
    useState<AvatarTypeObject>();
  const [avatarTypeList, setAvatarTypeList] = useState<Array<AvatarTypeObject>>(
    []
  );
  const [avatarType, setAvatarType] = useState<AvatarTypeObject>({
    name: "",
    visible: false,
    enabled: false,
    pk: 0,
  });

  useEffect(() => {
    setIsLoading(true);
    var organization = { pk: 0 };
    if (localStorage.getItem("organization")) {
      organization = JSON.parse(localStorage.getItem("organization") ?? "");
    }
    Get(getAvatarTypeList(organization.pk)).then((val) => {
      if (val.status && val.status < 300) {
        if (val.data && val.data.data) {
          setAvatarTypeList(val.data.data);
          //Possible todo handle pages
          // setCount(val.data.page_count)
          if (val.data.data.length > 0) {
            val.data.data.forEach((type: AssetTypeObject, index: number) => {
              Get(getAvatarAssetsTypes(type.pk)).then((val1) => {
                if (val1.status && val1.status < 300) {
                  setAvatarTypeList((prev) => {
                    prev[index].asset_types = val1.data.data;
                    return prev;
                  });
                  setIsLoading(false);
                } else {
                  if (val1.status === 401) {
                    navigator("/login");
                  }
                }
              });
            });
          } else {
            setIsLoading(false);
          }
        }
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  function handleUpdateType(index: number) {
    setErrorMessage("");
    setInfoMessage("");
    setIsLoading(true);
    const formDataUpdate = new FormData();
    formDataUpdate.append(
      "enabled",
      avatarTypeList[index].enabled ? "true" : "false"
    );
    formDataUpdate.append(
      "visible",
      avatarTypeList[index].visible ? "true" : "false"
    );
    formDataUpdate.append("name", avatarTypeList[index].name);
    formDataUpdate.append("priority", "0");
    Post(updateAvatarType(avatarTypeList[index].pk), formDataUpdate).then(
      (val) => {
        if (val.status && val.status < 300) {
          setInfoMessage("Avatar type updated.");
          props.updateAvatarTypeList(avatarTypeList);
        } else {
          if (val.status === 401) {
            navigator("/login");
          } else {
            //handle errors
            var temp = Object.keys(val.data.form.errors)[0];
            setErrorMessage(val.data.form.errors[temp]);
          }
        }
        setIsLoading(false);
      }
    );
  }

  function handleDelete(pk: number) {
    setErrorMessage("");
    setInfoMessage("");
    const deleteForm = new FormData();
    setIsLoading(true);
    Post(deleteAvatarType(pk), deleteForm).then((val) => {
      if (val.status && val.status < 300) {
        setInfoMessage("Avatar type deleted.");
        setAvatarTypeList(avatarTypeList.filter((x) => x.pk !== pk));
        props.updateAvatarTypeList(avatarTypeList.filter((x) => x.pk !== pk));
      } else {
        if (val.status === 401) {
          navigator("/login");
        } else {
          //handle errors
          var temp = Object.keys(val.data.form.errors)[0];
          setErrorMessage(val.data.form.errors[temp]);
        }
      }
      setIsLoading(false);
    });
  }

  function createType() {
    setErrorMessage("");
    setInfoMessage("");
    //use new type
    setIsLoading(true);
    const formDataCreate = new FormData();
    formDataCreate.append("enabled", avatarType.enabled ? "true" : "false");
    formDataCreate.append("visible", avatarType.visible ? "true" : "false");
    formDataCreate.append("name", avatarType.name);
    formDataCreate.append("priority", "0");
    var organization = { pk: 0 };
    if (localStorage.getItem("organization")) {
      organization = JSON.parse(localStorage.getItem("organization") ?? "");
    }
    Post(createAvatarType(organization.pk), formDataCreate).then((val) => {
      if (val.status && val.status < 300) {
        setInfoMessage("Avatar type created.");
        setAvatarTypeList((prev) => [...prev, val.data.data]);
        props.updateAvatarTypeList(avatarTypeList.concat(val.data.data));
        setAvatarType({
          name: "",
          visible: false,
          enabled: false,
          pk: 0,
        });
      } else {
        if (val.status === 401) {
          navigator("/login");
        } else {
          //handle errors
          var temp = Object.keys(val.data.form.errors)[0];
          setErrorMessage(val.data.form.errors[temp]);
        }
      }
      setIsLoading(false);
    });
  }

  function handleNewTypeChange(e: React.ChangeEvent<HTMLInputElement>) {
    setAvatarType({ ...avatarType, [e.target.name]: e.target.value });
  }

  function handleNewTypeCheck(e: React.ChangeEvent<HTMLInputElement>) {
    setAvatarType({ ...avatarType, [e.target.name]: e.target.checked });
  }

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) {
    setAvatarTypeList((prev) => {
      const newtype = prev.map((obj, i) => {
        if (i === index) {
          return { ...obj, [e.target.name]: e.target.value };
        }
        return obj;
      });
      return newtype;
    });
  }

  function handleCheck(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    setAvatarTypeList((prev) => {
      const newtype = prev.map((obj, i) => {
        if (i === index) {
          return { ...obj, [e.target.name]: e.target.checked };
        }
        return obj;
      });
      return newtype;
    });
  }

  return (
    <Modal
      open={props.openEditTypeModal}
      onClose={() => props.setOpenEditTypeModal(false)}
    >
      <Box style={style}>
        <EditAssetType
          isOpen={editAssetTypesModal}
          handleClose={() => {
            setSelectedAvatarType(undefined);
            setEditAssetTypesModal(false);
          }}
          assetTypeList={
            selectedAvatarType && selectedAvatarType.asset_types
              ? selectedAvatarType.asset_types
              : []
          }
          avatarTypeName={selectedAvatarType?.name}
          avatarTypePK={selectedAvatarType ? selectedAvatarType.pk : 0}
          updateAvatarList={(assetTypeList, avatarTypePk) => {
            setAvatarTypeList((prev) => {
              const newList = prev;
              const index = newList.findIndex((x) => x.pk === avatarTypePk);
              newList[index].asset_types = assetTypeList;
              return newList;
            });
          }}
        />
        {!isLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {infoMessage !== "" && (
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                {infoMessage}
              </Alert>
            )}
            {errorMessage !== "" && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {errorMessage}
              </Alert>
            )}
            <div style={{ fontSize: "2rem", textAlign: "center" }}>
              Edit Avatar Types
            </div>
            <TableContainer style={{ width: "100%" }}>
              <Table sx={{ tableLayout: "fixed", width: "auto" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Enabled</TableCell>
                    <TableCell>Visible</TableCell>
                    <TableCell>Create/Update</TableCell>
                    <TableCell>Update Asset Types</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={0}>
                    <TableCell sx={{ minWidth: "100px" }}>
                      <TextField
                        label="Name"
                        required
                        value={avatarType.name}
                        name="name"
                        onChange={handleNewTypeChange}
                      />
                    </TableCell>
                    <TableCell>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="enabled"
                              checked={avatarType.enabled}
                              onChange={handleNewTypeCheck}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Enabled"
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="visible"
                              checked={avatarType.visible}
                              onChange={handleNewTypeCheck}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Visible"
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        sx={{ my: 1 }}
                        onClick={() => createType()}
                      >
                        <Typography color="white">
                          Create Avatar Type
                        </Typography>
                      </Button>
                    </TableCell>
                  </TableRow>
                  {avatarTypeList.map((data, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>
                          <TextField
                            label="Name"
                            required
                            value={data.name}
                            name="name"
                            onChange={(e) => handleChange(e, i)}
                          />
                        </TableCell>
                        <TableCell>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="enabled"
                                  checked={avatarTypeList[i].enabled}
                                  onChange={(e) => handleCheck(e, i)}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                              label="Enabled"
                            />
                          </FormGroup>
                        </TableCell>
                        <TableCell>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="visible"
                                  checked={avatarTypeList[i].visible}
                                  onChange={(e) => handleCheck(e, i)}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                              label="Visible"
                            />
                          </FormGroup>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            sx={{ my: 1 }}
                            onClick={() => handleUpdateType(i)}
                          >
                            <Typography color="white">
                              Update Avatar Type
                            </Typography>
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            sx={{ my: 1 }}
                            onClick={() => {
                              setEditAssetTypesModal(true);
                              setSelectedAvatarType(data);
                            }}
                          >
                            <Typography color="white">
                              Edit Asset Types
                            </Typography>
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            sx={{ my: 1 }}
                            onClick={() => handleDelete(avatarTypeList[i].pk)}
                            color="error"
                          >
                            <Typography color="white">
                              Delete Avatar Type
                            </Typography>
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              onClick={() => props.setOpenEditTypeModal(false)}
              variant="contained"
              sx={{ mx: 2, my: 2 }}
            >
              <Typography color="white">Cancel</Typography>
            </Button>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </Box>
    </Modal>
  );
}
