import {
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getAvatarTypeList } from "../../../../utility/Endpoints";
import Get from "../../../../utility/Get";
import EditAvatarType from "./EditAvatarType";

type AvatarType = {
  enabled: boolean;
  name: string;
  pk: number;
  visible: boolean;
};

interface AvatarTypeListProps {
  typeSelected: (type: any) => void;
  typeList?: Array<AvatarType>;
}
/**
 * Contains all Avatar related components
 * @returns
 */
export default function AvatarTypeList(
  props: AvatarTypeListProps
): JSX.Element {
  let navigator = useNavigate();
  const [typeList, setTypeList] = useState<Array<AvatarType>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1); //number of total pages
  const [openEditTypeModal, setOpenEditTypeModal] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    var organization = { pk: 0 }
    if (localStorage.getItem("organization")) {
      organization = JSON.parse(localStorage.getItem("organization") ?? "")
    }
    Get(getAvatarTypeList(organization.pk)).then((val) => {
      if (val.status && val.status < 300) {
        if (val.data && val.data.data) {
          setTypeList(val.data.data);
          setCount(val.data.page_count);
        }
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
      setIsLoading(false);
    });
  }, [navigator]);

  useEffect(() => {
    if(props.typeList) {
      setTypeList(props.typeList)
    }
  }, [props])

  function handleChange(event: React.ChangeEvent<unknown>, page: number) {
    event.preventDefault();
    setPage(page);
  }

  return !isLoading ? (
    <div className="avatar-type-list">
      <EditAvatarType
        openEditTypeModal={openEditTypeModal}
        setOpenEditTypeModal={setOpenEditTypeModal}
        updateAvatarTypeList={(list) => setTypeList(list)}
      />
      <Pagination
        count={count}
        page={page}
        onChange={handleChange}
        color="primary"
        sx={{ display: "flex", justifyContent: "center" }}
      />
      {typeList.length === 0 ? (
        <div>No Avatar Types found.</div>
      ) : (
        <TableContainer sx={{ display: "flex", justifyContent: "center" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Enabled</TableCell>
                <TableCell>Visible</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {typeList.map((data, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      <Button onClick={() => props.typeSelected(data)}>
                        {data.name}
                      </Button>
                    </TableCell>
                    <TableCell>
                      {data.enabled ? (
                        <CheckIcon sx={{ color: "green" }} />
                      ) : (
                        <CloseIcon sx={{ color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell>
                      {data.visible ? (
                        <CheckIcon sx={{ color: "green" }} />
                      ) : (
                        <CloseIcon sx={{ color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => setOpenEditTypeModal(true)}>
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  ) : (
    <div>Loading...</div>
  );
}
