import { Alert, AlertTitle, Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { updatePassword, updateUsername, v3Login } from "../../utility/Endpoints";
import Post from "../../utility/Post";

interface AccountProps {
  user: any
}

/**
 * View and change User information on the database
 * @returns
 */
export default function Account(props: AccountProps): JSX.Element {
  let navigator = useNavigate()
  const [username, setUsername] = useState("")
  const [session, setSession] = useState({
    current_password: "",
    password: "",
    password_confirm: ""
  })
  const [usernameError, setUsernameError] = useState("")
  const [currentPasswordError, setCurrentPasswordError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [confirmPasswordError, setConfirmPasswordError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [infoMessage, setInfoMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if (props.user && props.user.username) {
      setUsername(props.user.username)
    }
  }, [props.user])

  function handleUpdateOrgForm() {
    setErrorMessage("")
    setInfoMessage("")
    //if form is completed
    if (username !== "") {
      const formData = new FormData();
      formData.append("username", username)
      setIsLoading(true)
      Post(updateUsername(props.user.pk), formData).then((val) => {
        if (val.status < 300) {
          setIsLoading(false)
          setInfoMessage("Username Updated")
        } else {
          if (val.status === 401) {
            navigator("/login")
          }
          //handle errors
          var temp = Object.keys(val.data.form.errors)[0]
          setUsernameError(val.data.form.errors[temp])
          setErrorMessage(val.data.form.errors[temp])
          setIsLoading(false)
        }
      });
    } else {
      setUsernameError("Enter a username")
      setIsLoading(false)
    }

  }

  //update backend with user's new password
  function updateBackendPassword() {
    setErrorMessage("")
    setInfoMessage("")
    //check passwords
    var error = false
    if (session.current_password.length === 0 || session.current_password === "") {
      setCurrentPasswordError("Enter current password")
      error = true
    } else {
      setCurrentPasswordError("")
    }

    if (session.password_confirm.length === 0 || session.password_confirm === "") {
      setConfirmPasswordError("Enter confirm new password")
      error = true
    }
    // check if passwords match
    //check is passwords are at least 8 letters and contains at least one number
    if (session.password.length === 0 || session.password === "") {
      setPasswordError("Enter new password")
      error = true
    }
    else if (!(/\d/.test(session.password)) || session.password.length < 8) {
      error = true
      setPasswordError("Password must contain 8 Characters, Letters AND Numbers")
    }
    else if (session.password !== session.password_confirm) {
      error = true
      setPasswordError("Passwords do not match")
      setConfirmPasswordError("Passwords do not match")
    }
    else {
      setPasswordError("")
      setConfirmPasswordError("")
    }

    if (!error) {
      setIsLoading(true)
      const formData1 = new FormData();
      formData1.append("current_password", session.current_password);
      formData1.append("password", session.password);
      formData1.append("password_confirm", session.password_confirm);

      Post(updatePassword(props.user.pk), formData1).then(res => {
        if (res.status && res.status < 300) {
          var organization = { pk: 0 }
          if (localStorage.getItem("organization")) {
            organization = JSON.parse(localStorage.getItem("organization") ?? "")
          }
          const formData = new FormData();
          formData.append("username", res.data.data.username);
          formData.append("password", session.password);
          Post(v3Login(organization.pk), formData).then((res1) => {
            if (res1.status && res1.status < 300) {
              localStorage.setItem("sessionid", res1.data.data.sessionid);
              setIsLoading(false)
              setInfoMessage("Password has been reset")
              setSession({
                current_password: "",
                password: "",
                password_confirm: ""
              })
            } else {
              if (res1.status === 401) {
                navigator("/login")
              }
              setErrorMessage("An error occurred, please try again later.")
            }

          });
        }
        else {
          if (res.status === 401) {
            navigator("/login")
          }
          setIsLoading(false)
          if (res.data && res.data.form && res.data.form.errors) {
            setErrorMessage(`${Object.values(res.data.form.errors)[0]}`)
          } else {
            setErrorMessage("An error occurred, please try again later.")
          }
        }
      })
    }
  }


  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.name === "username") {
      setUsername(e.target.value)
    } else {
      setSession({ ...session, [e.target.name]: e.target.value })
    }
  }

  return (
    <Box className="account">
      {infoMessage !== "" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {infoMessage}
        </Alert>
      )}
      {errorMessage !== "" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}
      <Grid container>
        <Grid container item xs={12} textAlign="center">
          <Grid item xs={12}>
            <Typography fontSize="32px">Change Username</Typography>
          </Grid>
          <Grid item xs={12} sx={{ my: 0.5 }}>
            <TextField
              name="username"
              label="Username"
              value={username}
              onChange={handleChange}
              error={usernameError !== ""}
              helperText={usernameError}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{ mt: 0.5 }}
              onClick={handleUpdateOrgForm}
              disabled={isLoading}
            >
              <Typography color="white">Change Username</Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid container item xs={12} textAlign="center">
          <Grid item xs={12}>
            <Typography fontSize="32px">Change Password</Typography>
          </Grid>
          <Grid item xs={12} sx={{ my: 0.5 }}>
            <TextField
              label="Current Password"
              type="password"
              value={session.current_password}
              onChange={handleChange}
              name="current_password"
              error={currentPasswordError !== ""}
              helperText={currentPasswordError}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12} sx={{ my: 0.5 }}>
            <TextField
              label="New Password"
              type="password"
              value={session.password}
              onChange={handleChange}
              name="password"
              error={passwordError !== ""}
              helperText={passwordError}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12} sx={{ my: 0.5 }}>
            <TextField
              label="Confirm Password"
              type="password"
              value={session.password_confirm}
              onChange={handleChange}
              name="password_confirm"
              error={confirmPasswordError !== ""}
              helperText={confirmPasswordError}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{ mt: 0.5 }}
              onClick={updateBackendPassword}
              disabled={isLoading}
            >
              <Typography color="white">Change Password</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
