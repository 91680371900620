import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";

export default function Faq() {
  return (
    <>
      <Navigation />
      <Box component="div" className="faq">
        <Accordion>
          <AccordionSummary>
            <Typography sx={{ fontWeight: "bold" }}>
              How do I use Preedicition’s Instance of Vstreamer?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We’ve got a video tutorial on the basics
              <br />
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/kyjreoivClA"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Typography>
            <br />
            <Typography>
              We also show you how to stream or record videos with a Vstreamer
              avatar using OBS’ free software
              <br />
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/a6_eeIKd8gY"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography sx={{ fontWeight: "bold" }}>
              Can I get additional avatars or emotes?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Please reach out to Aaron or Tony for any avatar or emote
              requests. Preediction has a certain amount of credits available
              for asset creation, and they’ll need to approve the allocation.
            </Typography>
            <br />
            <Typography>
              If approved, you’ll work with someone from{" "}
              <a
                href="https://beneverending.com/"
                target="_blank"
                rel="noreferrer"
              >
                NeverEnding’s
              </a>{" "}
              team to have your avatar, emote, background or other request
              fulfilled.
            </Typography>
            <br />
            <Typography>
              In 2023 you’ll be able to create custom avatars using
              NeverEnding’s Character Builder at BeNeverEnding.app. You’ll be
              able to use the custom avatar you created in Vstreamer.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Footer />
      </Box>
    </>
  );
}
