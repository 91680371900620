import {
  Alert,
  AlertTitle,
  Link,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getUserListPrivate } from "../../../utility/Endpoints";
import Get from "../../../utility/Get";

interface UserListProps {
  search?: string,
  errorMessage?: string,
  infoMessage?: string
}

/**
 * Displays a list of all users from the database
 * @returns
 */
export default function UserList(props: UserListProps): JSX.Element {
  let navigator = useNavigate()
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(1) //number of total pages
  const [users, setUsers] = useState<{
    username: string,
    email: string,
    avatar_count: number,
    is_organization_manager: boolean,
    is_user_manager: boolean,
    is_active: boolean,
    pk: number
  }[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [infoMessage, setInfoMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    setPage(1)
  }, [props.search])

  useEffect(() => {
    getUsers()
    // eslint-disable-next-line
  }, [page, props.search])

  useEffect(() => {
    if (props.errorMessage) {
      setErrorMessage(props.errorMessage)
    }
    else {
      setErrorMessage("")
    }
    if (props.infoMessage) {
      setInfoMessage(props.infoMessage)
    } else {
      setInfoMessage("")
    }
    getUsers()
    // eslint-disable-next-line
  }, [props.infoMessage, props.errorMessage])


  function getUsers() {
    setIsLoading(true)
    var organization = { pk: 0 }
    if (localStorage.getItem("organization")) {
      organization = JSON.parse(localStorage.getItem("organization") ?? "")
    }
    Get(getUserListPrivate(organization.pk, page, props.search)).then(val => {
      if (val.status && val.status < 300) {
        setUsers(val.data.data)
        setCount(val.data.page_count)
        setIsLoading(false)
      } else {
        if (val.status === 401) {
          navigator("/login")
        }
      }
    })
  }

  function handleChange(event: React.ChangeEvent<unknown>, page: number) {
    event.preventDefault()
    setPage(page)
  }

  return !isLoading ? (
    <div className="user-list">
      {infoMessage !== "" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {infoMessage}
        </Alert>
      )}
      {errorMessage !== "" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}
      <Pagination
        count={count}
        page={page}
        onChange={handleChange}
        color="primary"
        sx={{ display: "flex", justifyContent: "center" }}
      />
      {users.length === 0 ? (
        <div>No users found.</div>
      ) : (
        <TableContainer sx={{ display: "flex", justifyContent: "center" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Avatar Count</TableCell>
                <TableCell>User Manager</TableCell>
                <TableCell>Organization Manager</TableCell>
                <TableCell>Active</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((data, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      <Link href={"/" + data.pk + "/edit"}>{data.username ?? `User ${data.pk}`}</Link>
                    </TableCell>
                    <TableCell>{data.email}</TableCell>
                    <TableCell>{data.avatar_count}</TableCell>
                    <TableCell>
                      {data.is_user_manager ?
                        <CheckIcon sx={{ color: "green" }} /> :
                        <CloseIcon sx={{ color: "red" }} />
                      }
                    </TableCell>
                    <TableCell>
                      {data.is_organization_manager ?
                        <CheckIcon sx={{ color: "green" }} /> :
                        <CloseIcon sx={{ color: "red" }} />
                      }
                    </TableCell>
                    <TableCell>
                      {data.is_active ?
                        <CheckIcon sx={{ color: "green" }} /> :
                        <CloseIcon sx={{ color: "red" }} />
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  ) : (
    <div>Loading...</div>
  );
}
