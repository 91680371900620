import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  createAssetVarientFrame,
  createAttachmentPoint,
  createAvatarAsset,
  createAvatarAssetVarient,
  createAvatarObject,
  deleteAttachmentPoint,
  deleteVarient,
  deleteVarientFrame,
  getAvatarAssetsTypes,
  getAvatarStates,
  updateAvatarAsset,
} from "../../../../utility/Endpoints";
import Get from "../../../../utility/Get";
import Post from "../../../../utility/Post";
import EditIcon from "@mui/icons-material/Edit";
import EditAvatarStates from "./EditAvatarStates";
import {
  AssetStateObject,
  AssetTypeObject,
  AssetVarient,
  AssetVarientFramesObject,
  AttachmentPoint,
  AvatarObject,
} from "../../../../utility/Types";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

interface AvatarFormProps {
  avatarType?: any;
  avatar?: AvatarObject;
  updateAvatar?: (
    newAvatar: AvatarObject,
    thumbnail_image?: HTMLInputElement,
    images?: { [key: string]: File | null },
    assetTypeList?: Array<AssetTypeObject>,
    avatarStateList?: Array<AssetStateObject>
  ) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style: React.CSSProperties = {
  backgroundColor: "white",
  padding: "1.5rem",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  overflow: "auto",
};

/**
 * Contains all Avatar related components
 * @returns
 */
export default function AvatarForm(props: AvatarFormProps): JSX.Element {
  let navigator = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [infoMessage, setInfoMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [session, setSession] = useState<AvatarObject>({
    name: "",
    is_public: false,
    avatar_type: 0,
    user: 0, //missing thumbnail image
    assets: {}, //array of asset objects (interval, states, name, width, image, etc)
  });
  //list of asset types that are needed for the avatar type
  const [assetTypeList, setAssetTypeList] = useState<Array<AssetTypeObject>>(
    []
  );
  //list of avatar states
  const [avatarStateList, setAvatarStateList] = useState<
    Array<AssetStateObject>
  >([]);
  //add or delete avatar states in modal
  const [openEditStateModal, setOpenEditStateModal] = useState(false);
  //delete confirm modals
  const [openDeleteAssetVarientModal, setOpenDeleteAssetVarientModal] =
    useState({
      modal: false,
      assetType: "",
      varient_index: 0,
    });
  const [openDeleteVarientFrameModal, setOpenDeleteVarientFrameModal] =
    useState({
      modal: false,
      assetType: "",
      index: 0,
      frame_index: 0,
    });
  const [openDeleteAttachmentPointModal, setOpenDeleteAttachmentPointModal] =
    useState({
      modal: false,
      assetType: "",
      index: 0,
      varientIndex: 0,
      attachment_point_index: 0,
    });

  useEffect(() => {
    setIsLoading(true);
    if (props.avatarType) {
      getAvatarTypesAndStates(props.avatarType.pk);
    } else if (props.avatar) {
      getAvatarTypesAndStates(props.avatar.avatar_type);
      //Make a full copy of object hack
      setSession(JSON.parse(JSON.stringify(props.avatar)));
    }
    // eslint-disable-next-line
  }, [props]);

  function getAvatarTypesAndStates(avatarTypePk: number) {
    Get(getAvatarAssetsTypes(avatarTypePk)).then((val) => {
      if (val.status && val.status < 300) {
        //Note: Will need to handle pagination when that time comes
        if (val.data && val.data.data) {
          setAssetTypeList(val.data.data);
        }
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
      setIsLoading(false);
    });
    Get(getAvatarStates()).then((val) => {
      if (val.status && val.status < 300) {
        //Note: Will need to handle pagination when that time comes
        if (val.data && val.data.data) {
          setAvatarStateList(val.data.data);
        }
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
      setIsLoading(false);
    });
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    var error = false;
    setInfoMessage("");
    setErrorMessage("");
    //handle errors, empty strings
    if (session.name === "" || session.name.length === 0) {
      setErrorMessage("Name field required");
      error = true;
    }

    //check if all images have files
    let thumbnailFile = document.getElementById(
      "thumbnail_image"
    ) as HTMLInputElement;
    if (
      props.avatarType &&
      (thumbnailFile === undefined ||
        thumbnailFile.files === undefined ||
        thumbnailFile.files?.length === 0)
    ) {
      setErrorMessage("Thumbnail image cannot be empty");
      error = true;
    }

    //handle section not updated
    let assetKeys = Object.keys(session.assets);
    //skip and continue so artists dont have to upload every asset type
    // if (assetKeys.length !== assetTypeList.length) {
    //   setErrorMessage("Missing asset type information");
    //   error = true;
    // }
    //check and save all images before loading(true)
    var images: { [key: string]: File | null } = {};
    assetKeys.forEach((key) => {
      if (!session.assets[key].name || session.assets[key].name === "") {
        setErrorMessage("Name field required");
        error = true;
      } else if (
        !session.assets[key].width ||
        session.assets[key].width?.toString() === ""
      ) {
        setErrorMessage("Width field required");
        error = true;
      } else if (
        !session.assets[key].assetvarient ||
        session.assets[key].assetvarient?.toString() === ""
      ) {
        setErrorMessage("Please add an asset varient");
        error = true;
      } else {
        session.assets[key].assetvarient.forEach((variant, i) => {
          var priorities: Array<number> = [];
          if (
            variant.animationinterval === undefined ||
            variant.animationinterval?.toString() === ""
          ) {
            setErrorMessage("Animation Interval field required");
            error = true;
          } else if (variant.animationinterval < 0) {
            setErrorMessage("Animation Interval field should be not negative");
            error = true;
          } else if (
            !variant.avatarstates ||
            variant.avatarstates?.toString() === ""
          ) {
            setErrorMessage("Avatar state field required");
            error = true;
          }

          variant.assetvarientframes.forEach((frame) => {
            //check priorities are different numbers
            if (priorities.includes(frame.priority)) {
              setErrorMessage("Priorities must be different");
              error = true;
            } else {
              priorities.push(frame.priority);
            }

            //hanlde attachment point name empty
            frame.attactmentpoint.forEach((point) => {
              if (point.name === "" || point.name.length === 0) {
                setErrorMessage("Attachment point must have a name");
                error = true;
              }
              if (point.assettype.pk === 0) {
                setErrorMessage(
                  "Attachment point must have a connecting asset type"
                );
                error = true;
              }
            });
          });
          variant.assetvarientframes.forEach((_, index) => {
            let image = document.getElementById(
              `image_${i}_${index}_${key}`
            ) as HTMLInputElement;
            if (
              props.avatarType &&
              (image === undefined ||
                image === null ||
                image.files === undefined ||
                image.files?.length === 0)
            ) {
              setErrorMessage("Missing Image field");
              error = true;
            } else {
              images[`image_${i}_${index}_${key}`] =
                image && image.files ? image.files[0] : null;
            }

            //get bacak images
            let imageBack = document.getElementById(
              `imageBack_${i}_${index}_${key}`
            ) as HTMLInputElement;
            if (
              props.avatarType &&
              (imageBack === undefined ||
                imageBack === null ||
                imageBack.files === undefined ||
                imageBack.files?.length === 0)
            ) {
              //dont throw error cause it is optional
            } else {
              images[`imageBack_${i}_${index}_${key}`] =
                imageBack && imageBack.files ? imageBack.files[0] : null;
            }
          });
        });
      }
    });

    if (!error) {
      if (props.avatar && props.updateAvatar) {
        props.updateAvatar(
          session,
          thumbnailFile,
          images,
          assetTypeList,
          avatarStateList
        );
      } else if (props.avatarType) {
        setIsLoading(true);
        /**
         * Avatar:
         * user pk (local)
         * avatar type pk (props)
         * name
         * thumbnail image
         */
        var user = { pk: 0 };
        if (localStorage.getItem("vstreamer_user")) {
          user = JSON.parse(localStorage.getItem("vstreamer_user") ?? "");
        }
        const FormData = require("form-data");
        const formDataAvatar = new FormData();

        formDataAvatar.append("name", session.name);
        formDataAvatar.append("avatar_type", props.avatarType.pk);
        formDataAvatar.append(
          "image",
          thumbnailFile && thumbnailFile.files ? thumbnailFile.files[0] : ""
        );
        formDataAvatar.append("is_public", session.is_public);
        Post(createAvatarObject(user.pk), formDataAvatar).then((val1) => {
          if (val1.status && val1.status < 300) {

            setTimeout(() => {
              setIsLoading(false)
            }, 3000)
            //for each asset type
            /**
             * Asset:
             * asset type (get and filter by section)
             * name
             * width
             */
            if (val1.data && val1.data.data) {
              let assetKeys = Object.keys(session.assets);
              assetKeys.forEach((key, j) => {
                let asset_pk_index = assetTypeList.findIndex(
                  (x) => x.name === key
                );
                const formDataAssets = new FormData();
                formDataAssets.append("name", session.assets[key].name);
                formDataAssets.append("width", session.assets[key].width);
                //TODO Handle this later
                formDataAssets.append("color_accent_count", 0);
                formDataAssets.append("asset_set", 1);
                Post(
                  createAvatarAsset(assetTypeList[asset_pk_index].pk),
                  formDataAssets
                ).then((val2) => {
                  if (val2.status && val2.status < 300) {
                    if (val2.data && val2.data.data) {
                      /**
                       * Update avatar assets
                       * connect assets to avatar assets
                       */
                      const formDataAvatarAsset = new FormData();
                      formDataAvatarAsset.append("avatar", val1.data.data.pk);
                      formDataAvatarAsset.append("asset", val2.data.data.pk);
                      formDataAvatarAsset.append(
                        "asset_type",
                        assetTypeList[asset_pk_index].pk
                      );
                      //TODO handle this later
                      formDataAvatarAsset.append("color_base", "ffffff");
                      //connect the right asset to the avatar asset type
                      let type_index = val1.data.data.avatar_assets.findIndex(
                        (x: any) =>
                          x.asset_type.name ===
                          assetTypeList[asset_pk_index].name
                      );
                      Post(
                        updateAvatarAsset(
                          val1.data.data.avatar_assets[type_index].pk
                        ),
                        formDataAvatarAsset
                      ).then((val4) => {
                        if (val4.status && val4.status < 300) {
                        } else {
                          if (val4.status === 401) {
                            navigator("/login");
                          } else {
                            //handle errors
                            var temp = Object.keys(val4.data.form.errors)[0];
                            setErrorMessage(val4.data.form.errors[temp]);
                          }
                        }
                      });

                      /**
                       * Asset varient:
                       * asset (from above)
                       * animation interval
                       * avatar state
                       */
                      session.assets[key].assetvarient.forEach((v, ii) => {
                        const formDataVarient = new FormData();
                        v.avatarstates?.forEach((state) => {
                          let index = avatarStateList.findIndex(
                            (x) => x.name === state
                          );
                          formDataVarient.append(
                            "avatar_states",
                            avatarStateList[index].pk
                          );
                        });
                        formDataVarient.append(
                          "animation_interval",
                          v.animationinterval
                        );
                        if (v.animationstartintervalmax) {
                          formDataVarient.append(
                            "animation_start_interval_max",
                            v.animationstartintervalmax
                          );
                        }
                        if (v.animationstartintervalmin) {
                          formDataVarient.append(
                            "animation_start_interval_min",
                            v.animationstartintervalmin
                          );
                        }
                        Post(
                          createAvatarAssetVarient(val2.data.data.pk),
                          formDataVarient
                        ).then((val3) => {
                          if (val3.status && val3.status < 300) {
                            if (val3.data && val3.data.data) {
                              //for each image
                              /**
                               * Asset varient frames:
                               * image
                               * priority
                               * asset varient (from above)
                               */
                              v.assetvarientframes.forEach(
                                (varientFrame, index) => {
                                  const formDataVarientFrame = new FormData();
                                  formDataVarientFrame.append(
                                    "image",
                                    images[`image_${ii}_${index}_${key}`]
                                  );
                                  if (
                                    images[`imageBack_${ii}_${index}_${key}`]
                                  ) {
                                    formDataVarientFrame.append(
                                      "image_back",
                                      images[`imageBack_${ii}_${index}_${key}`]
                                    );
                                  }
                                  formDataVarientFrame.append(
                                    "priority",
                                    varientFrame.priority
                                  );
                                  Post(
                                    createAssetVarientFrame(val3.data.data.pk),
                                    formDataVarientFrame
                                  ).then((val5) => {
                                    if (val5.status && val5.status < 300) {
                                      //handle creating attachment points
                                      if (val5.data && val5.data.data) {
                                        //for each attachment point
                                        /**
                                         * Attachment points:
                                         * asset varient frame (from above)
                                         * name
                                         * x
                                         * y
                                         * z
                                         */
                                        varientFrame.attactmentpoint.forEach(
                                          (point, i) => {
                                            const formDataAttachmentPoint =
                                              new FormData();
                                            formDataAttachmentPoint.append(
                                              "name",
                                              point.name
                                            );
                                            formDataAttachmentPoint.append(
                                              "x_position",
                                              point.x
                                            );
                                            formDataAttachmentPoint.append(
                                              "y_position",
                                              point.y
                                            );
                                            formDataAttachmentPoint.append(
                                              "z_index",
                                              point.z
                                            );
                                            formDataAttachmentPoint.append(
                                              "z_index_back",
                                              point.zback ? point.zback : 0
                                            );

                                            formDataAttachmentPoint.append(
                                              "asset_type",
                                              point.assettype.pk
                                            );
                                            Post(
                                              createAttachmentPoint(
                                                val5.data.data.pk
                                              ),
                                              formDataAttachmentPoint
                                            ).then((val6) => {
                                              if (
                                                val6.status &&
                                                val6.status < 300
                                              ) {
                                                setInfoMessage(
                                                  "Avatar Created!"
                                                );
                                              } else {
                                                if (val6.status === 401) {
                                                  navigator("/login");
                                                } else {
                                                  //handle errors
                                                  var temp = Object.keys(
                                                    val6.data.form.errors
                                                  )[0];
                                                  setErrorMessage(
                                                    val6.data.form.errors[temp]
                                                  );
                                                }
                                              }

                                              //if last api call, then set loading to false
                                              if (
                                                assetKeys.length - 1 === j &&
                                                session.assets[key].assetvarient
                                                  .length -
                                                  1 ===
                                                  index &&
                                                varientFrame.attactmentpoint
                                                  .length -
                                                  1 ===
                                                  i
                                              ) {
                                                setIsLoading(false);
                                                //redirect to edit page
                                                navigator(
                                                  `/avatarmanagement/${val1.data.data.pk}/edit`
                                                );
                                              }
                                            });
                                          }
                                        );
                                      }
                                    } else {
                                      if (val5.status === 401) {
                                        navigator("/login");
                                      } else {
                                        //handle errors
                                        var temp = Object.keys(
                                          val5.data.form.errors
                                        )[0];
                                        setErrorMessage(
                                          val5.data.form.errors[temp]
                                        );
                                      }
                                    }
                                  });
                                }
                              );
                            }
                          } else {
                            if (val3.status === 401) {
                              navigator("/login");
                            } else {
                              //handle errors
                              var temp = Object.keys(val3.data.form.errors)[0];
                              setErrorMessage(val3.data.form.errors[temp]);
                            }
                          }
                        });
                      });
                    }
                  } else {
                    if (val2.status === 401) {
                      navigator("/login");
                    } else {
                      //handle errors
                      var temp = Object.keys(val2.data.form.errors)[0];
                      setErrorMessage(val2.data.form.errors[temp]);
                    }
                  }
                });
              });
            }
          } else {
            if (val1.status === 401) {
              navigator("/login");
            } else {
              //handle errors
              var temp = Object.keys(val1.data.form.errors)[0];
              setErrorMessage(val1.data.form.errors[temp]);
            }
          }
        });
      }
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSession({ ...session, [e.target.name]: e.target.value });
  }

  function handleCheck(e: React.ChangeEvent<HTMLInputElement>) {
    setSession({ ...session, [e.target.name]: e.target.checked });
  }

  function handleAssetChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setSession((prev) => {
      const indexes = e.target.name.split("_");
      const assets = { ...prev.assets };
      assets[indexes[1]] = {
        ...assets[indexes[1]],
        [indexes[0]]: e.target.value,
      };
      return { ...prev, assets };
    });
  }

  function handleVarientChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) {
    setSession((prev) => {
      const indexes = e.target.name.split("_");
      const assets = { ...prev.assets };
      assets[indexes[1]].assetvarient[index] = {
        ...assets[indexes[1]].assetvarient[index],
        [indexes[0]]: e.target.value === "" ? 0 : e.target.value,
      };
      return { ...prev, assets };
    });
  }

  const handleSelected = (event: any, index: number) => {
    const {
      target: { value, name },
    } = event;
    setSession((prev) => {
      const indexes = name.split("_");
      const assets = { ...prev.assets };
      assets[indexes[1]].assetvarient[index] = {
        ...assets[indexes[1]].assetvarient[index],
        [indexes[0]]: typeof value === "string" ? value.split(",") : value,
      };
      return { ...prev, assets };
    });
  };

  function handleAddAssetVarient(e: any, assetType: string) {
    // add asset varient fields to array
    setSession((prev) => {
      const assets = { ...prev.assets };
      var array: AssetVarient[];

      if (assets[assetType] && assets[assetType].assetvarient) {
        //if list exists, add to it
        array =
          assets[assetType] !== undefined ? assets[assetType].assetvarient : [];
        array.push({
          animationinterval: 0,
          avatarstates: [],
          assetvarientframes: [],
        });
        assets[assetType].assetvarient = array;
      } else {
        array = [
          { animationinterval: 0, avatarstates: [], assetvarientframes: [] },
        ];
        assets[assetType] = { ...assets[assetType], assetvarient: array };
      }
      return { ...prev, assets };
    });
  }

  function deleteAssetVarientObject(assetType: string, varient_index: number) {
    if (session.assets[assetType].assetvarient[varient_index].pk) {
      // delete all frames and attachment points and then remove varient
      session.assets[assetType].assetvarient[
        varient_index
      ].assetvarientframes.forEach((_, i) => {
        deleteVarientFrameObject(assetType, varient_index, i);
      });
      const deleteForm = new FormData();
      Post(
        deleteVarient(session.assets[assetType].assetvarient[varient_index].pk),
        deleteForm
      ).then((val) => {
        if (val.status && val.status < 300) {
          setInfoMessage("Asset Varient deleted");
          //then remove from session
          setSession((prev) => {
            const assets = { ...prev.assets };
            var array: AssetVarient[];
            array = assets[assetType].assetvarient;
            array = array.filter((_, x) => x !== varient_index);
            assets[assetType].assetvarient = array;
            return { ...prev, assets };
          });
        } else {
          if (val.status === 401) {
            navigator("/login");
          }
          setErrorMessage("Something went wrong");
        }
        setOpenDeleteAssetVarientModal({
          modal: false,
          assetType: "",
          varient_index: 0,
        });
      });
    } else {
      //if no pk, just remove from session
      setSession((prev) => {
        const assets = { ...prev.assets };
        var array: AssetVarient[];
        array = assets[assetType].assetvarient;
        array = array.filter((_, x) => x !== varient_index);
        assets[assetType].assetvarient = array;
        return { ...prev, assets };
      });
      setOpenDeleteAssetVarientModal({
        modal: false,
        assetType: "",
        varient_index: 0,
      });
    }
  }

  function handleAddAssetVarientFrame(
    e: any,
    assetType: string,
    index: number
  ) {
    // add asset varient frame fields to array
    setSession((prev) => {
      const assets = { ...prev.assets };
      var array: AssetVarientFramesObject[];
      if (
        assets[assetType] &&
        assets[assetType].assetvarient[index] &&
        assets[assetType].assetvarient[index].assetvarientframes
      ) {
        array =
          assets[assetType].assetvarient[index].assetvarientframes !== undefined
            ? assets[assetType].assetvarient[index].assetvarientframes
            : [];
        array.push({
          priority: 0,
          attactmentpoint: [
            { x: 0, y: 0, z: 0, zback: 0, name: "", assettype: { pk: 0 } },
          ],
        });
      } else {
        array = [
          {
            priority: 0,
            attactmentpoint: [
              { x: 0, y: 0, z: 0, zback: 0, name: "", assettype: { pk: 0 } },
            ],
          },
        ];
      }
      assets[assetType].assetvarient[index] = {
        ...assets[assetType].assetvarient[index],
        assetvarientframes: array,
      };
      return { ...prev, assets };
    });
  }

  function deleteVarientFrameObject(
    assetType: string,
    index: number,
    frame_index: number
  ) {
    //check if it has a pk
    if (
      session.assets[assetType].assetvarient[index].assetvarientframes[
        frame_index
      ].pk
    ) {
      //post remove every attachment point and then varient frame
      session.assets[assetType].assetvarient[index].assetvarientframes[
        frame_index
      ].attactmentpoint.forEach((_, i) => {
        deleteAttachmentPointObject(assetType, index, frame_index, i);
      });
      const deleteForm = new FormData();
      Post(
        deleteVarientFrame(
          session.assets[assetType].assetvarient[index].assetvarientframes[
            frame_index
          ].pk
        ),
        deleteForm
      ).then((val) => {
        if (val.status && val.status < 300) {
          setInfoMessage("Varient Frame deleted");
          //then remove from session
          setSession((prev) => {
            const assets = { ...prev.assets };
            var array: AssetVarientFramesObject[];
            array = assets[assetType].assetvarient[index].assetvarientframes;
            array = array.filter((_, x) => x !== frame_index);
            assets[assetType].assetvarient[index].assetvarientframes = array;
            return { ...prev, assets };
          });
        } else {
          if (val.status === 401) {
            navigator("/login");
          }
          setErrorMessage("Something went wrong");
        }
        setOpenDeleteVarientFrameModal({
          modal: false,
          assetType: "",
          index: 0,
          frame_index: 0,
        });
      });
    } else {
      //if no pk, just remove from session
      setSession((prev) => {
        const assets = { ...prev.assets };
        var array: AssetVarientFramesObject[];
        array = assets[assetType].assetvarient[index].assetvarientframes;
        array = array.filter((_, x) => x !== frame_index);
        assets[assetType].assetvarient[index].assetvarientframes = array;
        return { ...prev, assets };
      });
      setOpenDeleteVarientFrameModal({
        modal: false,
        assetType: "",
        index: 0,
        frame_index: 0,
      });
    }
  }

  function handleAssetVarientChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) {
    setSession((prev) => {
      const indexes = e.target.name.split("_"); //ie: priority, 0, mouth
      const assets = { ...prev.assets };
      assets[indexes[2]].assetvarient[index].assetvarientframes[
        indexes[1] as unknown as number
      ] = {
        ...assets[indexes[2]].assetvarient[index].assetvarientframes[
          indexes[1] as unknown as number
        ],
        [indexes[0]]: parseInt(e.target.value, 10),
      };
      return { ...prev, assets };
    });
  }

  function handleAttachmentPointChange(e: any, index: number) {
    let t = e.target.value;
    let value =
      t.indexOf(".") >= 0
        ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
        : t;
    setSession((prev) => {
      const indexes = e.target.name.split("_"); // name, varient frame index, attachment point index, mouth
      const assets = { ...prev.assets };
      assets[indexes[3]].assetvarient[index].assetvarientframes[
        indexes[1] as unknown as number
      ].attactmentpoint[indexes[2] as unknown as number] = {
        ...assets[indexes[3]].assetvarient[index].assetvarientframes[
          indexes[1] as unknown as number
        ].attactmentpoint[indexes[2] as unknown as number],
        [indexes[0]]: indexes[0] === "name" ? e.target.value : value,
      };
      return { ...prev, assets };
    });
  }

  function handleAttachmentPointSelected(event: any, index: number) {
    const {
      target: { value, name },
    } = event;
    setSession((prev) => {
      const indexes = name.split("_"); // name, varient frame index, attachment point index, mouth
      const assets = { ...prev.assets };
      var v = value === "string" ? value.split(",") : value;
      let assettype = assetTypeList.filter((x) => x.pk === v);
      assets[indexes[3]].assetvarient[index].assetvarientframes[
        indexes[1] as unknown as number
      ].attactmentpoint[indexes[2] as unknown as number] = {
        ...assets[indexes[3]].assetvarient[index].assetvarientframes[
          indexes[1] as unknown as number
        ].attactmentpoint[indexes[2] as unknown as number],
        [indexes[0]]: assettype.length > 0 ? assettype[0] : { pk: 0 },
      };
      return { ...prev, assets };
    });
  }

  function handleAddAttachmentPoint(
    e: any,
    assetType: string,
    index: number,
    varientIndex: number
  ) {
    setSession((prev) => {
      const assets = { ...prev.assets };
      var array: AttachmentPoint[];
      if (
        assets[assetType] &&
        assets[assetType].assetvarient[varientIndex] &&
        assets[assetType].assetvarient[varientIndex].assetvarientframes &&
        assets[assetType].assetvarient[varientIndex].assetvarientframes[index]
      ) {
        array =
          assets[assetType].assetvarient[varientIndex].assetvarientframes[
            index
          ] !== undefined
            ? assets[assetType].assetvarient[varientIndex].assetvarientframes[
                index
              ].attactmentpoint
            : [];
        array.push({
          x: 0,
          y: 0,
          z: 0,
          zback: 0,
          name: "",
          assettype: { pk: 0 },
        });
      } else {
        array = [
          { x: 0, y: 0, z: 0, zback: 0, name: "", assettype: { pk: 0 } },
        ];
      }
      assets[assetType].assetvarient[varientIndex].assetvarientframes[
        index
      ].attactmentpoint = array;
      return { ...prev, assets };
    });
  }

  function deleteAttachmentPointObject(
    assetType: string,
    index: number,
    varientIndex: number,
    attachment_point_index: number
  ) {
    //check if it has a pk
    if (
      session.assets[assetType].assetvarient[index].assetvarientframes[
        varientIndex
      ].attactmentpoint[attachment_point_index].pk
    ) {
      //post delete to bakcend
      const deleteForm = new FormData();

      Post(
        deleteAttachmentPoint(
          session.assets[assetType].assetvarient[index].assetvarientframes[
            varientIndex
          ].attactmentpoint[attachment_point_index].pk
        ),
        deleteForm
      ).then((val) => {
        if (val.status && val.status < 300) {
          // setInfoMessage("Attachment point deleted") //NOTE: uncommenting this causes multiple points to be removed
          //then remove from session
          setSession((prev) => {
            const assets = { ...prev.assets };
            var array: AttachmentPoint[];
            array =
              assets[assetType].assetvarient[index].assetvarientframes[
                varientIndex
              ].attactmentpoint;
            array = array.filter((_, x) => x !== attachment_point_index);
            assets[assetType].assetvarient[index].assetvarientframes[
              varientIndex
            ].attactmentpoint = array;
            return { ...prev, assets };
          });
        } else {
          if (val.status === 401) {
            navigator("/login");
          }
          setErrorMessage("Something went wrong");
        }
        setOpenDeleteAttachmentPointModal({
          modal: false,
          assetType: "",
          index: 0,
          varientIndex: 0,
          attachment_point_index: 0,
        });
      });
    } else {
      //remove attachment point if it doesnt have a pk
      setSession((prev) => {
        const assets = { ...prev.assets };
        var array: AttachmentPoint[];
        array =
          assets[assetType].assetvarient[index].assetvarientframes[varientIndex]
            .attactmentpoint;
        array = array.filter((_, x) => x !== attachment_point_index);
        assets[assetType].assetvarient[index].assetvarientframes[
          varientIndex
        ].attactmentpoint = array;
        return { ...prev, assets };
      });
      setOpenDeleteAttachmentPointModal({
        modal: false,
        assetType: "",
        index: 0,
        varientIndex: 0,
        attachment_point_index: 0,
      });
    }
  }

  return !isLoading ? (
    <div className="avatar-form">
      {infoMessage !== "" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {infoMessage}
        </Alert>
      )}
      {errorMessage !== "" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}
      {/* delete confirm modals  */}
      <Modal
        open={openDeleteAssetVarientModal.modal}
        onClose={() => {
          setOpenDeleteAssetVarientModal({
            modal: false,
            assetType: "",
            varient_index: 0,
          });
        }}
      >
        <Box style={style}>
          <h2>Delete?</h2>
          <div>Are you sure you want to delete this Asset Variant?</div>
          <Button
            onClick={() => {
              deleteAssetVarientObject(
                openDeleteAssetVarientModal.assetType,
                openDeleteAssetVarientModal.varient_index
              );
            }}
            color="error"
            variant="contained"
            sx={{ my: 1 }}
          >
            Delete
          </Button>
          <Button
            onClick={() =>
              setOpenDeleteAssetVarientModal({
                modal: false,
                assetType: "",
                varient_index: 0,
              })
            }
            variant="contained"
            sx={{ my: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
      <Modal
        open={openDeleteVarientFrameModal.modal}
        onClose={() => {
          setOpenDeleteVarientFrameModal({
            modal: false,
            assetType: "",
            index: 0,
            frame_index: 0,
          });
        }}
      >
        <Box style={style}>
          <h2>Delete?</h2>
          <div>Are you sure you want to delete this Variant Frame?</div>
          <Button
            onClick={() => {
              deleteVarientFrameObject(
                openDeleteVarientFrameModal.assetType,
                openDeleteVarientFrameModal.index,
                openDeleteVarientFrameModal.frame_index
              );
            }}
            color="error"
            variant="contained"
            sx={{ my: 1 }}
          >
            Delete
          </Button>
          <Button
            onClick={() =>
              setOpenDeleteVarientFrameModal({
                modal: false,
                assetType: "",
                index: 0,
                frame_index: 0,
              })
            }
            variant="contained"
            sx={{ my: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
      <Modal
        open={openDeleteAttachmentPointModal.modal}
        onClose={() => {
          setOpenDeleteAttachmentPointModal({
            modal: false,
            assetType: "",
            index: 0,
            varientIndex: 0,
            attachment_point_index: 0,
          });
        }}
      >
        <Box style={style}>
          <h2>Delete?</h2>
          <div>Are you sure you want to delete this Attachment Point?</div>
          <Button
            onClick={() => {
              deleteAttachmentPointObject(
                openDeleteAttachmentPointModal.assetType,
                openDeleteAttachmentPointModal.index,
                openDeleteAttachmentPointModal.varientIndex,
                openDeleteAttachmentPointModal.attachment_point_index
              );
            }}
            color="error"
            variant="contained"
            sx={{ my: 1 }}
          >
            Delete
          </Button>
          <Button
            onClick={() =>
              setOpenDeleteAttachmentPointModal({
                modal: false,
                assetType: "",
                index: 0,
                varientIndex: 0,
                attachment_point_index: 0,
              })
            }
            variant="contained"
            sx={{ my: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
      {/* end delete confirm modals  */}
      <EditAvatarStates
        openEditStateModal={openEditStateModal}
        setOpenEditStateModal={setOpenEditStateModal}
        avatarStateList={avatarStateList}
        setAvatarStateList={setAvatarStateList}
      />
      <Box>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <TextField
              margin="dense"
              label="Name"
              required
              value={session.name}
              name="name"
              onChange={handleChange}
              disabled={isLoading}
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_public"
                    checked={session.is_public}
                    onChange={handleCheck}
                    inputProps={{ "aria-label": "controlled" }}
                    disabled={isLoading}
                  />
                }
                label="Public"
              />
            </FormGroup>
            <Button component="label" variant="outlined">
              <span
                id={`assetFormUploadThumbnailImage`}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AddIcon />
                Upload Thumbnail Image
              </span>
              <input
                type="file"
                hidden
                required
                id="thumbnail_image"
                name="thumbnail_image"
                onChange={(e) => {
                  const element = document.getElementById(
                    `assetFormUploadThumbnailImage`
                  );

                  if (element)
                    element.innerText =
                      e.target.value.split("\\")[
                        e.target.value.split("\\").length - 1
                      ];
                }}
                disabled={isLoading}
              />
            </Button>
            {session.image && (
              <>
                <h4>Current Thumbnail Image</h4>
                <Box
                  component="img"
                  sx={{
                    width: 350,
                    maxWidth: { xs: 350, md: 250 },
                  }}
                  alt="Current Thumbnail"
                  src={session.image}
                />
              </>
            )}
            {assetTypeList.map((assetType, typeIndex) => {
              return (
                <div
                  key={typeIndex}
                  className="avatar-form__section"
                  style={{ backgroundColor: "white" }}
                >
                  <h2>{assetType.name ? assetType.name.toUpperCase() : ""}</h2>
                  <TextField
                    margin="dense"
                    label="Name"
                    required
                    value={
                      session.assets[assetType.name] &&
                      session.assets[assetType.name].name
                        ? session.assets[assetType.name].name
                        : ""
                    }
                    name={`name_${assetType.name}`}
                    onChange={(e) => handleAssetChange(e)}
                    disabled={isLoading}
                    sx={{ marginRight: "1rem" }}
                  />
                  <TextField
                    margin="dense"
                    label="Width (Default: 2400px)"
                    required
                    value={
                      session.assets[assetType.name] &&
                      session.assets[assetType.name].width
                        ? session.assets[assetType.name].width
                        : ""
                    }
                    name={`width_${assetType.name}`}
                    type="number"
                    onChange={(e) => handleAssetChange(e)}
                    disabled={isLoading}
                  />
                  {session.assets[assetType.name] &&
                    session.assets[assetType.name].assetvarient &&
                    session.assets[assetType.name].assetvarient.map(
                      (varient, indexx) => {
                        return (
                          <div
                            className="avatar-form__asset-varient"
                            key={indexx}
                            style={{ backgroundColor: "#C1CFEB" }}
                          >
                            <FormControl>
                              {/* have multiple sections available  */}
                              <h4>Asset Variant</h4>
                              <FormControl>
                                <TextField
                                  margin="dense"
                                  label="Animation Interval"
                                  required
                                  value={
                                    varient && varient.animationinterval
                                      ? varient.animationinterval
                                      : 0
                                  }
                                  name={`animationinterval_${assetType.name}`}
                                  type="number"
                                  onChange={(e) =>
                                    handleVarientChange(e, indexx)
                                  }
                                  disabled={isLoading}
                                />
                                <span
                                  style={{
                                    color: "#0288d1",
                                    marginBottom: ".5rem",
                                  }}
                                >
                                  <b>Animation Interval</b> is the time between
                                  each frame in milliseconds
                                </span>
                              </FormControl>

                              <FormControl>
                                <TextField
                                  margin="dense"
                                  label="Animation Frequency Interval Min"
                                  value={
                                    varient && varient.animationstartintervalmin
                                      ? varient.animationstartintervalmin
                                      : 0
                                  }
                                  name={`animationstartintervalmin_${assetType.name}`}
                                  type="number"
                                  onChange={(e) =>
                                    handleVarientChange(e, indexx)
                                  }
                                  disabled={isLoading}
                                />
                                <span
                                  style={{
                                    color: "#0288d1",
                                    marginBottom: ".5rem",
                                  }}
                                >
                                  <b>Animation Frequency Interval Min</b> is the
                                  minimum amount of time before the animation
                                  starts in milliseconds
                                </span>
                              </FormControl>
                              <FormControl>
                                <TextField
                                  margin="dense"
                                  label="Animation Frequency Interval Max"
                                  value={
                                    varient && varient.animationstartintervalmax
                                      ? varient.animationstartintervalmax
                                      : 0
                                  }
                                  name={`animationstartintervalmax_${assetType.name}`}
                                  type="number"
                                  onChange={(e) =>
                                    handleVarientChange(e, indexx)
                                  }
                                  disabled={isLoading}
                                />
                                <span
                                  style={{
                                    color: "#0288d1",
                                    marginBottom: ".5rem",
                                  }}
                                >
                                  <b>Animation Frequency Interval Max</b> is the
                                  maximum amount of time before the animation
                                  starts in milliseconds
                                </span>
                              </FormControl>

                              <FormControl>
                                <InputLabel id="demo-multiple-checkbox-label">
                                  Avatar State
                                </InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  name={`avatarstates_${assetType.name}`}
                                  value={
                                    varient && varient.avatarstates
                                      ? varient.avatarstates
                                      : []
                                  }
                                  onChange={(e) => handleSelected(e, indexx)}
                                  input={<OutlinedInput label="Tag" />}
                                  renderValue={(selected) =>
                                    selected.join(", ")
                                  }
                                  MenuProps={MenuProps}
                                  style={{ letterSpacing: "1rem" }}
                                >
                                  {avatarStateList.map((state) => (
                                    <MenuItem
                                      key={state.name}
                                      value={state.name}
                                    >
                                      <Checkbox
                                        checked={
                                          varient && varient.avatarstates
                                            ? varient.avatarstates?.includes(
                                                state.name
                                              )
                                            : false
                                        }
                                      />
                                      <ListItemText primary={state.name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              <Button
                                variant="outlined"
                                sx={{ margin: "1rem 0" }}
                                onClick={() => setOpenEditStateModal(true)}
                                color="info"
                              >
                                Edit States
                                <EditIcon />
                              </Button>
                              {varient.assetvarientframes.map((frame, j) => {
                                //get convert to image to get width and height
                                const imgSrc = frame.image ? frame.image : "";
                                const img = new Image();
                                img.src = imgSrc;
                                //find ratio to keep aspect ratio of image
                                var ratio = Math.min(
                                  350 / img.width,
                                  350 / img.height
                                );
                                return (
                                  <div
                                    className="avatar-form__varient-frame"
                                    key={j}
                                    style={{ backgroundColor: "#A3B7E1" }}
                                  >
                                    <div className="avatar-form__variant-frame-section">
                                      <h3>Variant Frame</h3>
                                      {frame && frame.image && img && (
                                        <>
                                          <div>Current Frame</div>
                                          <div
                                            style={{
                                              position: "relative",
                                              height: "350px",
                                            }}
                                          >
                                            {/* // this will be the "frame" of the point  */}
                                            <div
                                              style={{
                                                // height: "350px",
                                                // maxHeight: "250px",
                                                height:
                                                  img.height * ratio
                                                    ? img.height * ratio
                                                    : "auto",
                                                position: "absolute",
                                                zIndex: "10000",
                                                width:
                                                  img.width * ratio
                                                    ? img.width * ratio
                                                    : "350px",
                                              }}
                                            >
                                              {ratio &&
                                                img &&
                                                frame.attactmentpoint.map(
                                                  (point) => {
                                                    return (
                                                      // {/* this is the actual point  */}
                                                      <div
                                                        style={{
                                                          marginTop: `calc(${point.x}% - 6px)`,
                                                          marginLeft: `calc(${point.y}% - 6px)`,
                                                          position: "absolute",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            height: "10px",
                                                            width: "10px",
                                                            backgroundColor:
                                                              "red",
                                                            borderRadius: "50%",
                                                            display:
                                                              "inline-block",
                                                          }}
                                                        ></span>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </div>
                                            <Box
                                              component="img"
                                              sx={{
                                                height:
                                                  img.height * ratio
                                                    ? img.height * ratio
                                                    : "350px", //350,
                                                // maxHeight: { xs: 350, md: 250 },
                                                position: "absolute",
                                                zIndex: 2,
                                              }}
                                              alt="Current Frame"
                                              src={frame.image}
                                            />
                                            {frame.imageBack && (
                                              <Box
                                                component="img"
                                                sx={{
                                                  height:
                                                    img.height * ratio
                                                      ? img.height * ratio
                                                      : "350px", //350,
                                                  // maxHeight: { xs: 350, md: 250 },
                                                  position: "absolute",
                                                  top: 0,
                                                  left: 0,
                                                  zIndex: 1,
                                                }}
                                                alt="Current Frame Back"
                                                src={frame.imageBack}
                                              />
                                            )}
                                          </div>
                                        </>
                                      )}
                                      <FormControl fullWidth>
                                        <TextField
                                          margin="dense"
                                          label="Priority"
                                          required
                                          value={frame.priority}
                                          name={`priority_${j}_${assetType.name}`}
                                          type="number"
                                          onChange={(e) =>
                                            handleAssetVarientChange(e, indexx)
                                          }
                                          disabled={isLoading}
                                        />
                                      </FormControl>
                                      <FormGroup row>
                                        <FormControl fullWidth>
                                          <Button
                                            component="label"
                                            variant="outlined"
                                            className="cmsm"
                                          >
                                            <span
                                              id={`image_${indexx}_${j}_${assetType.name}_value`}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <AddIcon />
                                              Upload Asset Image
                                            </span>
                                            <input
                                              // margin="dense"
                                              // label="Asset Image"
                                              required
                                              id={`image_${indexx}_${j}_${assetType.name}`} //varient index, frame index, asset type
                                              name={`image_${indexx}_${j}_${assetType.name}`}
                                              type="file"
                                              disabled={isLoading}
                                              onChange={(e) => {
                                                const element =
                                                  document.getElementById(
                                                    `image_${indexx}_${j}_${assetType.name}_value`
                                                  );

                                                if (element)
                                                  element.innerText =
                                                    e.target.value.split("\\")[
                                                      e.target.value.split("\\")
                                                        .length - 1
                                                    ];
                                              }}
                                              hidden
                                            />
                                          </Button>
                                        </FormControl>
                                        <FormControl fullWidth>
                                          <Button
                                            component="label"
                                            variant="outlined"
                                            className="cmsm"
                                          >
                                            <span
                                              id={`imageBack_${indexx}_${j}_${assetType.name}_value`}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <AddIcon />
                                              Upload Asset Image Back
                                            </span>
                                            <input
                                              // margin="dense"
                                              // label="Asset Image"
                                              id={`imageBack_${indexx}_${j}_${assetType.name}`} //varient index, frame index, asset type
                                              name={`imageBack_${indexx}_${j}_${assetType.name}`}
                                              type="file"
                                              disabled={isLoading}
                                              onChange={(e) => {
                                                const element =
                                                  document.getElementById(
                                                    `imageBack_${indexx}_${j}_${assetType.name}_value`
                                                  );

                                                if (element)
                                                  element.innerText =
                                                    e.target.value.split("\\")[
                                                      e.target.value.split("\\")
                                                        .length - 1
                                                    ];
                                              }}
                                              hidden
                                            />
                                          </Button>
                                        </FormControl>
                                        <FormControl fullWidth>
                                          <Typography color="error">
                                            <b>Delete Asset Variant Frame</b>{" "}
                                            will also remove any{" "}
                                            <b>Attachment Points</b>{" "}
                                            associated!&nbsp;
                                          </Typography>
                                          <Button
                                            color="error"
                                            variant="outlined"
                                            onClick={() => {
                                              setOpenDeleteVarientFrameModal({
                                                modal: true,
                                                assetType: assetType.name,
                                                index: indexx,
                                                frame_index: j,
                                              });
                                              // deleteVarientFrameObject(assetType.name, indexx, j)
                                            }}
                                          >
                                            <DeleteIcon />
                                            Delete Variant Frame
                                          </Button>
                                        </FormControl>
                                      </FormGroup>
                                    </div>

                                    <div className="avatar-form__attachment-point-section">
                                      {/* handle attachment points  */}
                                      {frame &&
                                        frame.attactmentpoint.map(
                                          (point, index2) => {
                                            return (
                                              <div
                                                className="avatar-form__attachment-point"
                                                key={index2}
                                              >
                                                <h3>Attachment Point</h3>
                                                <FormGroup row>
                                                  <FormControl>
                                                    <TextField
                                                      margin="dense"
                                                      label="Name"
                                                      required
                                                      value={point.name}
                                                      name={`name_${j}_${index2}_${assetType.name}`}
                                                      onChange={(e) =>
                                                        handleAttachmentPointChange(
                                                          e,
                                                          indexx
                                                        )
                                                      }
                                                      disabled={isLoading}
                                                    />
                                                  </FormControl>
                                                  <FormControl
                                                    margin="dense"
                                                    sx={{
                                                      minWidth: "50%",
                                                      marginLeft: "1rem",
                                                    }}
                                                  >
                                                    <InputLabel id="multiple-checkbox-label">
                                                      Asset Type Connection
                                                    </InputLabel>
                                                    <Select
                                                      labelId="multiple-checkbox-label"
                                                      id="multiple-checkbox"
                                                      value={point.assettype.pk}
                                                      name={`assettype_${j}_${index2}_${assetType.name}`}
                                                      onChange={(e) =>
                                                        handleAttachmentPointSelected(
                                                          e,
                                                          indexx
                                                        )
                                                      }
                                                      input={
                                                        <OutlinedInput label="Tag" />
                                                      }
                                                      renderValue={(
                                                        selected
                                                      ) => {
                                                        return assetTypeList.filter(
                                                          (x) =>
                                                            x.pk === selected
                                                        ).length > 0
                                                          ? assetTypeList.filter(
                                                              (x) =>
                                                                x.pk ===
                                                                selected
                                                            )[0].name
                                                          : "";
                                                      }}
                                                      style={{
                                                        letterSpacing: "2.2rem",
                                                      }}
                                                    >
                                                      {/* list all other asset types, not the current one. aka body cannot be attached to body  */}
                                                      {assetTypeList
                                                        .filter(
                                                          (x) =>
                                                            x.pk !==
                                                            assetType.pk
                                                        )
                                                        .map(
                                                          (
                                                            assetType,
                                                            index
                                                          ) => (
                                                            <MenuItem
                                                              key={index}
                                                              value={
                                                                assetType.pk
                                                              }
                                                            >
                                                              <ListItemText
                                                                primary={
                                                                  assetType.name
                                                                }
                                                              />
                                                            </MenuItem>
                                                          )
                                                        )}
                                                    </Select>
                                                    <span
                                                      style={{
                                                        color: "#0288d1",
                                                      }}
                                                    >
                                                      Example: Eyes attach to
                                                      Head
                                                    </span>
                                                  </FormControl>
                                                </FormGroup>

                                                <FormGroup
                                                  row
                                                  sx={{
                                                    justifyContent:
                                                      "flex-start",
                                                  }}
                                                >
                                                  <TextField
                                                    margin="dense"
                                                    label="X"
                                                    required
                                                    value={point.x}
                                                    name={`x_${j}_${index2}_${assetType.name}`}
                                                    type="number"
                                                    onChange={(e) =>
                                                      handleAttachmentPointChange(
                                                        e,
                                                        indexx
                                                      )
                                                    }
                                                    sx={{
                                                      marginRight: "1rem",
                                                    }}
                                                    disabled={isLoading}
                                                  />
                                                  <TextField
                                                    margin="dense"
                                                    label="Y"
                                                    required
                                                    value={point.y}
                                                    name={`y_${j}_${index2}_${assetType.name}`}
                                                    type="number"
                                                    onChange={(e) =>
                                                      handleAttachmentPointChange(
                                                        e,
                                                        indexx
                                                      )
                                                    }
                                                    sx={{
                                                      marginRight: "1rem",
                                                    }}
                                                    disabled={isLoading}
                                                  />
                                                  <TextField
                                                    margin="dense"
                                                    label="Z"
                                                    required
                                                    value={point.z}
                                                    name={`z_${j}_${index2}_${assetType.name}`}
                                                    type="number"
                                                    onChange={(e) =>
                                                      handleAttachmentPointChange(
                                                        e,
                                                        indexx
                                                      )
                                                    }
                                                    disabled={isLoading}
                                                  />
                                                  <TextField
                                                    margin="dense"
                                                    label="Z Back"
                                                    value={point.zback}
                                                    name={`zback_${j}_${index2}_${assetType.name}`}
                                                    type="number"
                                                    onChange={(e) =>
                                                      handleAttachmentPointChange(
                                                        e,
                                                        indexx
                                                      )
                                                    }
                                                    disabled={isLoading}
                                                  />
                                                </FormGroup>

                                                <FormControl>
                                                  <span
                                                    style={{ color: "#0288d1" }}
                                                  >
                                                    <b>Z index back</b> are
                                                    optional.
                                                  </span>
                                                  <span
                                                    style={{ color: "#0288d1" }}
                                                  >
                                                    <b>X</b> and <b>Y</b> are in
                                                    percentages
                                                  </span>
                                                </FormControl>

                                                <FormGroup
                                                  row
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                  }}
                                                >
                                                  <FormControl
                                                    sx={{
                                                      marginRight: "1rem",
                                                    }}
                                                  >
                                                    <Button
                                                      color="error"
                                                      fullWidth
                                                      variant="outlined"
                                                      onClick={() => {
                                                        setOpenDeleteAttachmentPointModal(
                                                          {
                                                            modal: true,
                                                            assetType:
                                                              assetType.name,
                                                            index: j,
                                                            varientIndex:
                                                              indexx,
                                                            attachment_point_index:
                                                              index2,
                                                          }
                                                        );
                                                        // deleteAttachmentPointObject(assetType.name, j, indexx, index2)
                                                      }}
                                                    >
                                                      <DeleteIcon />
                                                      Delete Attachment Point
                                                    </Button>
                                                  </FormControl>
                                                  <FormControl>
                                                    <Button
                                                      variant="contained"
                                                      fullWidth
                                                      onClick={(e) =>
                                                        handleAddAttachmentPoint(
                                                          e,
                                                          assetType.name,
                                                          j,
                                                          indexx
                                                        )
                                                      }
                                                      sx={{ color: "white" }}
                                                    >
                                                      Add Attachment Point
                                                    </Button>
                                                  </FormControl>
                                                </FormGroup>
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  </div>
                                );
                              })}

                              <Button
                                className="cmsm"
                                variant="contained"
                                fullWidth
                                onClick={(e) =>
                                  handleAddAssetVarientFrame(
                                    e,
                                    assetType.name,
                                    indexx
                                  )
                                }
                                sx={{ color: "white" }}
                              >
                                Add Asset Variant Frame
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                  setOpenDeleteAssetVarientModal({
                                    modal: true,
                                    assetType: assetType.name,
                                    varient_index: indexx,
                                  });
                                  // deleteAssetVarientObject(assetType.name, indexx)
                                }}
                              >
                                <DeleteIcon />
                                Delete Asset Variant
                              </Button>
                              <Typography color="error">
                                <b>Delete Asset Variant</b> will also remove any
                                frames and <b>Attachment Points</b> associated!
                              </Typography>
                            </FormControl>
                          </div>
                        );
                      }
                    )}
                  <Button
                    onClick={(e) => handleAddAssetVarient(e, assetType.name)}
                    className="cmsm"
                    variant="contained"
                    sx={{ color: "white" }}
                    fullWidth
                  >
                    Add Asset Variant
                  </Button>
                </div>
              );
            })}
            <Button
              variant="contained"
              sx={{ my: 1 }}
              type="submit"
              onClick={handleSubmit}
            >
              <Typography color="white">Save Avatar</Typography>
            </Button>
          </FormControl>
        </form>
      </Box>
    </div>
  ) : (
    <div>Loading...</div>
  );
}
