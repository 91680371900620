/**
 * Determine if the user is logged in
 * @returns
 */
export function checkUserAuth(): boolean {
  if (localStorage.getItem("sessionid")) {
    return true;
  }
  return false;
}
