import { Alert, AlertTitle, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Topbar from "../../../components/Topbar";
import {
  createAssetVarientFrame,
  createAttachmentPoint,
  createAvatarAsset,
  createAvatarAssetVarient,
  deleteAvatar,
  getAvatarDetails,
  updateAsset,
  updateAttachmentPoint,
  updateAvatarAsset,
  updateAvatarObject,
  updateVarient,
  updateVarientFrame
} from "../../../utility/Endpoints";
import Get from "../../../utility/Get";
import Post from "../../../utility/Post";
import {
  AssetObject,
  AssetStateObject,
  AssetTypeObject,
  AssetVarient,
  AssetVarientFramesObject,
  AttachmentPoint,
  AvatarObject
} from "../../../utility/Types";
import AvatarForm from "./AddAvatar/AvatarForm";

/**
 * Update a specific Avatar on the database
 * @returns
 */
export default function EditAvatar(): JSX.Element {
  let navigator = useNavigate()
  const location = useLocation();
  const [avatar, setAvatar] = useState<AvatarObject>()
  const [isLoading, setIsLoading] = useState(false)
  const [noAvatarFound, setNoAvatarFound] = useState(false)
  const [infoMessage, setInfoMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    getAvatarDetailsAndConvert()
    // eslint-disable-next-line
  }, [])

  function getAvatarDetailsAndConvert() {
    let path = location.pathname.split("/")
    setIsLoading(true)
    Get(getAvatarDetails(path[2])).then(val => {
      if (val.status && val.status < 300) {
        if (val.data && val.data.data && val.data.data.avatar_assets) {
          //Convert backend data to  work in the avatarform
          let assets: { [key: string]: AssetObject } = {}
          val.data.data.avatar_assets.forEach((assetType: any) => {
            if (assetType.asset && assetType.asset.asset_varients) {
              let asset: Array<AssetVarient> = []
              assetType.asset.asset_varients.forEach((a: any) => {
                let state_pks: Array<string> = []
                if (a.avatar_state_list) {
                  a.avatar_state_list.forEach((state: any) => {
                    if (state.name) state_pks.push(state.name)
                  })
                }

                let assetvarientframes: Array<AssetVarientFramesObject> = []
                if (a.frames) {
                  a.frames.forEach((frame: any) => {
                    let attachmentPoints: Array<AttachmentPoint> = []
                    frame.attachment_points.forEach((point: any) => {
                      attachmentPoints.push({
                        name: point.name ? point.name : "",
                        x: point.x_position ? point.x_position : 0,
                        y: point.y_position ? point.y_position : 0,
                        z: point.z_index ? point.z_index : 0,
                        zback: point.z_index_back ? point.z_index_back : 0,
                        pk: point.pk ? point.pk : 0,
                        assettype: point.asset_type ? point.asset_type : { pk: 0 }
                      })
                    })
                    assetvarientframes.push({
                      priority: frame.priority ? frame.priority : 0,
                      attactmentpoint: attachmentPoints,
                      image: frame.image ? frame.image : "",
                      imageBack: frame.image_back ? frame.image_back : "",
                      pk: frame.pk ? frame.pk : 0
                    })
                  })
                }

                asset.push({
                  animationinterval: a.animation_interval ? a.animation_interval : 0,
                  animationstartintervalmax: a.animation_start_interval_max ? a.animation_start_interval_max : 0,
                  animationstartintervalmin: a.animation_start_interval_min ? a.animation_start_interval_min : 0,
                  assetvarientframes: assetvarientframes,
                  avatarstates: state_pks,
                  pk: a.pk ? a.pk : 0
                })
              })
              assets[assetType.asset_type.name] = {
                name: assetType.asset.name ? assetType.asset.name : "",
                width: assetType.asset.width ? assetType.asset.width : 0,
                pk: assetType.asset.pk ? assetType.asset.pk : 0,
                assetvarient: asset
              }
            } else {
              // setErrorMessage("Some information was not recovered. Please check and update avatar inputs")
            }

          });
          var user = { pk: 0 }
          if (localStorage.getItem("vstreamer_user")) {
            user = JSON.parse(localStorage.getItem("vstreamer_user") ?? "")
          }
          setAvatar({
            name: val.data.data.name,
            avatar_type: val.data.data.avatar_type.pk,
            user: user.pk,
            assets: assets,
            image: val.data.data.image,
            pk: val.data.data.pk,
            is_public: val.data.data.is_public,
          })
          setIsLoading(false)
        }
      }
      else {
        if (val.status === 401) {
          navigator("/login")
        }
        setIsLoading(false)
        setNoAvatarFound(true)
      }
    })
  }

  function handleDeleteAvatar() {
    setErrorMessage("")
    setInfoMessage("")
    setIsLoading(true)
    let path = location.pathname.split("/")
    const deleteForm = new FormData()
    Post(deleteAvatar(path[2]), deleteForm).then(val => {
      if (val.status && val.status < 300) {
        setIsLoading(false)
        setNoAvatarFound(true)
        setInfoMessage("Avatar Deleted")
      } else {
        if (val.status === 401) {
          navigator("/login")
        }
        setErrorMessage("Something went wrong")
      }
    })

  }

  function updateAvatar(
    newAvatar: AvatarObject,
    thumbnail_image: HTMLInputElement | undefined,
    images: { [key: string]: File | null } | undefined,
    assetTypeList: Array<AssetTypeObject> | undefined,
    avatarStateList: Array<AssetStateObject> | undefined
  ) {
    setInfoMessage("")
    setErrorMessage("")
    //compare new avatar and prev avatar and call updates based on what changed

    const FormData = require("form-data");

    //if avatar object changed, then update
    if (avatar && avatar.pk &&
      (newAvatar.name !== avatar.name ||
        (thumbnail_image && thumbnail_image.files && thumbnail_image.files[0]) ||
        (newAvatar.is_public !== avatar.is_public))
    ) {
      setIsLoading(true)
      const formDataAvatar = new FormData();
      formDataAvatar.append("name", newAvatar.name);
      formDataAvatar.append("avatar_type", newAvatar.avatar_type)
      formDataAvatar.append(
        "image",
        thumbnail_image && thumbnail_image.files ?
          thumbnail_image.files[0] :
          newAvatar.image ? newAvatar.image : ""
      )
      formDataAvatar.append("is_public", newAvatar.is_public)
      Post(updateAvatarObject(avatar.pk), formDataAvatar).then(val => {
        if (val.status && val.status < 300) {
          if (val.data && val.data.data && val.data.data.name && val.data.data.image) {
            setAvatar({
              ...avatar,
              name: val.data.data.name,
              image: val.data.data.image,
              is_public: val.data.data.is_public,
            })
            setInfoMessage("Avatar Updated")
            setIsLoading(false)
          }
        } else {
          if (val.status === 401) {
            navigator("/login")
          }
          else {
            //handle errors
            var temp = Object.keys(val.data.form.errors)[0]
            setErrorMessage(val.data.form.errors[temp])
            setIsLoading(false)
          }
        }
      })
    }

    //check if any assets changed (name or width)
    let assetKeys = Object.keys(newAvatar.assets)
    assetKeys.forEach(key => {
      var asset_pk_index = 0
      if (assetTypeList) {
        asset_pk_index = assetTypeList.findIndex(x => x.name === key)
      }

      if (avatar && newAvatar.assets[key].pk && assetTypeList &&
        (newAvatar.assets[key].name !== avatar.assets[key].name ||
          newAvatar.assets[key].width !== avatar.assets[key].width)) {
        const formDataAssets = new FormData();
        setIsLoading(true)

        formDataAssets.append("name", newAvatar.assets[key].name);
        formDataAssets.append("width", newAvatar.assets[key].width);
        formDataAssets.append("asset_type", assetTypeList[asset_pk_index].pk);
        formDataAssets.append("color_accent_count", 0);
        formDataAssets.append("asset_set", 1);
        Post(updateAsset(newAvatar.assets[key].pk), formDataAssets).then(val => {
          if (val.status && val.status < 300) {
            if (val.data && val.data.data) {
              setInfoMessage("Avatar Updated")
              //Get updated avatar
              getAvatarDetailsAndConvert()
            }
          } else {
            if (val.status === 401) {
              navigator("/login")
            }
            else {
              //handle errors
              var temp = Object.keys(val.data.form.errors)[0]
              setErrorMessage(val.data.form.errors[temp])
              setIsLoading(false)
            }
          }
        })
      }
      else if (avatar && !newAvatar.assets[key].pk && assetTypeList && avatarStateList && images) {
        //create asset AND connect
        const formDataAssets = new FormData();
        formDataAssets.append("name", newAvatar.assets[key].name);
        formDataAssets.append("width", newAvatar.assets[key].width);
        //TODO Handle this later
        formDataAssets.append("color_accent_count", 0);
        formDataAssets.append("asset_set", 1);
        Post(
          createAvatarAsset(assetTypeList[asset_pk_index].pk),
          formDataAssets
        ).then((val2) => {
          if (val2.status && val2.status < 300) {
            if (val2.data && val2.data.data) {
              /**
               * Update avatar assets
               * connect assets to avatar assets
               */
              const formDataAvatarAsset = new FormData();
              formDataAvatarAsset.append("avatar", avatar.pk);
              formDataAvatarAsset.append("asset", val2.data.data.pk);
              formDataAvatarAsset.append(
                "asset_type",
                assetTypeList[asset_pk_index].pk
              );
              //TODO handle this later
              formDataAvatarAsset.append("color_base", "ffffff");
              let path = location.pathname.split("/")
              Get(getAvatarDetails(path[2])).then(val1 => {
                if (val1.status && val1.status < 300) {
                  //connect the right asset to the avatar asset type
                  let type_index = val1.data.data.avatar_assets.findIndex(
                    (x: any) =>
                      x.asset_type.name ===
                      assetTypeList[asset_pk_index].name
                  );
                  Post(
                    updateAvatarAsset(
                      val1.data.data.avatar_assets[type_index].pk
                    ),
                    formDataAvatarAsset
                  ).then((val4) => {
                    if (val4.status && val4.status < 300) {
                    } else {
                      if (val4.status === 401) {
                        navigator("/login");
                      } else {
                        //handle errors
                        var temp = Object.keys(val4.data.form.errors)[0];
                        setErrorMessage(val4.data.form.errors[temp]);
                      }
                    }
                  });
                } else {
                  if (val1.status === 401) {
                    navigator("/login")
                  }
                  else {
                    //handle errors
                    var temp = Object.keys(val1.data.form.errors)[0]
                    setErrorMessage(val1.data.form.errors[temp])
                    setIsLoading(false)
                  }
                }
              });

              /**
               * Asset varient:
               * asset (from above)
               * animation interval
               * frequency/start interval min/max
               * avatar state
               */
              newAvatar.assets[key].assetvarient.forEach((v, ii) => {
                const formDataVarient = new FormData();
                v.avatarstates?.forEach((state) => {
                  let index = avatarStateList.findIndex(
                    (x) => x.name === state
                  );
                  formDataVarient.append("avatar_states", avatarStateList[index].pk);
                });
                formDataVarient.append("animation_interval", v.animationinterval);
                if (v.animationstartintervalmax) {
                  formDataVarient.append("animation_start_interval_max", v.animationstartintervalmax);
                }
                if (v.animationstartintervalmin) {
                  formDataVarient.append("animation_start_interval_min", v.animationstartintervalmin);
                }
                Post(
                  createAvatarAssetVarient(val2.data.data.pk),
                  formDataVarient
                ).then((val3) => {
                  if (val3.status && val3.status < 300) {
                    if (val3.data && val3.data.data) {
                      //for each image
                      /**
                       * Asset varient frames:
                       * image
                       * priority
                       * asset varient (from above)
                       */
                      v.assetvarientframes.forEach((varientFrame, index) => {
                        const formDataVarientFrame = new FormData()
                        formDataVarientFrame.append("image", images[`image_${ii}_${index}_${key}`])
                        if (images[`imageBack_${ii}_${index}_${key}`]) {
                          formDataVarientFrame.append("image_back", images[`imageBack_${ii}_${index}_${key}`])
                        }
                        formDataVarientFrame.append("priority", varientFrame.priority)
                        Post(createAssetVarientFrame(val3.data.data.pk), formDataVarientFrame).then(val5 => {
                          if (val5.status && val5.status < 300) {
                            //handle creating attachment points
                            if (val5.data && val5.data.data) {

                              //for each attachment point
                              /**
                               * Attachment points:
                               * asset varient frame (from above)
                               * name
                               * x
                               * y
                               * z
                               */
                              varientFrame.attactmentpoint.forEach((point, i) => {
                                const formDataAttachmentPoint = new FormData();
                                formDataAttachmentPoint.append("name", point.name)
                                formDataAttachmentPoint.append("x_position", point.x)
                                formDataAttachmentPoint.append("y_position", point.y)
                                formDataAttachmentPoint.append("z_index", point.z)
                                formDataAttachmentPoint.append("z_index_back", point.zback ? point.zback : 0)
                                formDataAttachmentPoint.append("asset_type", point.assettype.pk)
                                Post(createAttachmentPoint(val5.data.data.pk), formDataAttachmentPoint).then(val6 => {
                                  if (val6.status && val6.status < 300) {
                                    setInfoMessage("Avatar Created!")
                                  } else {
                                    if (val6.status === 401) {
                                      navigator("/login")
                                    }
                                    else {
                                      //handle errors
                                      var temp = Object.keys(val6.data.form.errors)[0]
                                      setErrorMessage(val6.data.form.errors[temp])
                                    }
                                  }
                                })
                              })
                            }
                          } else {
                            if (val5.status === 401) {
                              navigator("/login")
                            }
                            else {
                              //handle errors
                              var temp = Object.keys(val5.data.form.errors)[0]
                              setErrorMessage(val5.data.form.errors[temp])
                            }
                          }
                        })
                      })
                    }
                  } else {
                    if (val3.status === 401) {
                      navigator("/login");
                    } else {
                      //handle errors
                      var temp = Object.keys(val3.data.form.errors)[0];
                      setErrorMessage(val3.data.form.errors[temp]);
                    }
                  }
                });
              });
            }
          } else {
            if (val2.status === 401) {
              navigator("/login");
            } else {
              //handle errors
              var temp = Object.keys(val2.data.form.errors)[0];
              setErrorMessage(val2.data.form.errors[temp]);
            }
          }
        });
      }

      if (avatar && newAvatar.assets[key] && newAvatar.assets[key].pk && newAvatar.assets[key].assetvarient) {
        newAvatar.assets[key].assetvarient.forEach((varient, index) => {
          if (varient.animationinterval &&
            varient.pk &&
            varient.avatarstates &&
            (varient.animationinterval !== (avatar.assets[key].assetvarient[index] &&
              avatar.assets[key].assetvarient[index].animationinterval ?
              avatar.assets[key].assetvarient[index].animationinterval : 0) ||
              (varient.animationstartintervalmax !== (avatar.assets[key].assetvarient[index] &&
                avatar.assets[key].assetvarient[index].animationstartintervalmax ?
                avatar.assets[key].assetvarient[index].animationstartintervalmax : 0)) ||
              (varient.animationstartintervalmin !== (avatar.assets[key].assetvarient[index] &&
                avatar.assets[key].assetvarient[index].animationstartintervalmin ?
                avatar.assets[key].assetvarient[index].animationstartintervalmin : 0)) ||
              (
                JSON.stringify(varient.avatarstates) !== JSON.stringify(avatar.assets[key].assetvarient[index] &&
                  avatar.assets[key].assetvarient[index].avatarstates ?
                  avatar.assets[key].assetvarient[index].avatarstates : [])
              ))
          ) {
            setIsLoading(true)
            const formDataVarient = new FormData();
            varient.avatarstates?.forEach(state => {
              if (avatarStateList) {
                let index = avatarStateList.findIndex(x => x.name === state)
                formDataVarient.append("avatar_states", avatarStateList[index].pk)
              }
            })
            formDataVarient.append("animation_interval", varient.animationinterval);
            if (varient.animationstartintervalmax) {
              formDataVarient.append("animation_start_interval_max", varient.animationstartintervalmax);
            }
            if (varient.animationstartintervalmin) {
              formDataVarient.append("animation_start_interval_min", varient.animationstartintervalmin);
            }
            formDataVarient.append("asset", newAvatar.assets[key].pk)
            Post(updateVarient(varient.pk), formDataVarient).then(val => {
              if (val.status && val.status < 300) {
                if (val.data && val.data.data) {
                  setInfoMessage("Avatar Updated")
                  //Get updated avatar
                  getAvatarDetailsAndConvert()
                }
              } else {
                if (val.status === 401) {
                  navigator("/login")
                }
                else {
                  //handle errors
                  var temp = Object.keys(val.data.form.errors)[0]
                  setErrorMessage(val.data.form.errors[temp])
                  setIsLoading(false)
                }
              }
            })
          }
          else if ((varient.pk ? false : true) &&
            varient.avatarstates &&
            avatarStateList &&
            images
          ) {
            setIsLoading(true)
            const formDataVarient = new FormData();
            varient.avatarstates?.forEach(state => {
              let index = avatarStateList.findIndex(x => x.name === state)
              formDataVarient.append("avatar_states", avatarStateList[index].pk)
            })
            formDataVarient.append("animation_interval", varient.animationinterval ? varient.animationinterval : 0);
            if (varient.animationstartintervalmax) {
              formDataVarient.append("animation_start_interval_max", varient.animationstartintervalmax);
            }
            if (varient.animationstartintervalmin) {
              formDataVarient.append("animation_start_interval_min", varient.animationstartintervalmin);
            }
            Post(createAvatarAssetVarient(newAvatar.assets[key].pk), formDataVarient).then(val3 => {
              if (val3.status && val3.status < 300) {
                if (val3.data && val3.data.data) {
                  //for each image
                  /**
                   * Asset varient frames:
                   * image
                   * priority
                   * asset varient (from above)
                   */
                  varient.assetvarientframes.forEach((varientFrame, index1) => {
                    const formDataVarientFrame = new FormData()
                    formDataVarientFrame.append("image", images[`image_${index}_${index1}_${key}`])
                    if (images[`image_${index}_${index1}_${key}`]) {
                      formDataVarientFrame.append("image_back", images[`image_${index}_${index1}_${key}`])
                    }
                    formDataVarientFrame.append("priority", varientFrame.priority)
                    Post(createAssetVarientFrame(val3.data.data.pk), formDataVarientFrame).then(val5 => {
                      if (val5.status && val5.status < 300) {
                        //handle creating attachment points
                        if (val5.data && val5.data.data) {

                          //for each attachment point
                          /**
                           * Attachment points:
                           * asset varient frame (from above)
                           * name
                           * x
                           * y
                           * z
                           */
                          varientFrame.attactmentpoint.forEach((point, i) => {
                            const formDataAttachmentPoint = new FormData();
                            formDataAttachmentPoint.append("name", point.name)
                            formDataAttachmentPoint.append("x_position", point.x)
                            formDataAttachmentPoint.append("y_position", point.y)
                            formDataAttachmentPoint.append("z_index", point.z)
                            formDataAttachmentPoint.append("z_index_back", point.zback ? point.zback : 0)
                            formDataAttachmentPoint.append("asset_type", point.assettype.pk)
                            Post(createAttachmentPoint(val5.data.data.pk), formDataAttachmentPoint).then(val6 => {
                              if (val6.status && val6.status < 300) {
                                setInfoMessage("Avatar Updated")
                                //Get updated avatar
                                getAvatarDetailsAndConvert()
                              } else {
                                if (val6.status === 401) {
                                  navigator("/login")
                                }
                                else {
                                  //handle errors
                                  var temp = Object.keys(val6.data.form.errors)[0]
                                  setErrorMessage(val6.data.form.errors[temp])
                                  setIsLoading(false)
                                }
                              }
                            })
                          })
                        }
                      } else {
                        if (val5.status === 401) {
                          navigator("/login")
                        }
                        else {
                          //handle errors
                          var temp = Object.keys(val5.data.form.errors)[0]
                          setErrorMessage(val5.data.form.errors[temp])
                          setIsLoading(false)
                        }
                      }
                    })
                  })
                }
              }
              else {
                if (val3.status === 401) {
                  navigator("/login")
                }
                else {
                  //handle errors
                  var temp = Object.keys(val3.data.form.errors)[0]
                  setErrorMessage(val3.data.form.errors[temp])
                  setIsLoading(false)
                }
              }
            })
          }

          //check if varient frame changed and update
          if (varient.assetvarientframes) {
            varient.assetvarientframes.forEach((frame, index2) => {
              if (
                frame.pk &&
                varient.pk &&
                avatar.assets[key].assetvarient[index] &&
                avatar.assets[key].assetvarient[index].assetvarientframes[index2]
              ) {
                const formDataVarientFrame = new FormData()
                setIsLoading(true)
                formDataVarientFrame.append("priority", frame.priority)
                formDataVarientFrame.append("asset_varient", varient.pk)
                if (images && images[`image_${index}_${index2}_${key}`]) {
                  formDataVarientFrame.append("image", images[`image_${index}_${index2}_${key}`])
                } else {
                  formDataVarientFrame.append("image", frame.image ? frame.image : "")
                }
                if (images && images[`imageBack_${index}_${index2}_${key}`]) {
                  formDataVarientFrame.append("image_back", images[`imageBack_${index}_${index2}_${key}`])
                } else {
                  formDataVarientFrame.append("image_back", frame.imageBack ? frame.imageBack : "")
                }
                Post(updateVarientFrame(frame.pk), formDataVarientFrame).then(val => {
                  if (val.status && val.status < 300) {
                    if (val.data && val.data.data) {
                      setInfoMessage("Avatar Updated")
                      //Get updated avatar
                      getAvatarDetailsAndConvert()
                    }
                  } else {
                    if (val.status === 401) {
                      navigator("/login")
                    }
                    else {
                      //handle errors
                      var temp = Object.keys(val.data.form.errors)[0]
                      setErrorMessage(`${temp}: ${val.data.form.errors[temp]}`)
                      setIsLoading(false)
                    }
                  }
                })
              }
              else if (!frame.pk && images && varient.pk) {
                //if new frame, create frame and attachment points
                const formDataVarientFrame = new FormData()
                formDataVarientFrame.append("image", images[`image_${index}_${index2}_${key}`])
                if (images[`imageBack_${index}_${index2}_${key}`]) {
                  formDataVarientFrame.append("image_back", images[`imageBack_${index}_${index2}_${key}`])
                }
                formDataVarientFrame.append("priority", frame.priority)
                Post(createAssetVarientFrame(varient.pk), formDataVarientFrame).then(val5 => {
                  if (val5.status && val5.status < 300) {
                    //handle creating attachment points
                    if (val5.data && val5.data.data) {

                      //for each attachment point
                      /**
                       * Attachment points:
                       * asset varient frame (from above)
                       * name
                       * x
                       * y
                       * z
                       */
                      frame.attactmentpoint.forEach((point) => {
                        const formDataAttachmentPoint = new FormData();
                        formDataAttachmentPoint.append("name", point.name)
                        formDataAttachmentPoint.append("x_position", point.x)
                        formDataAttachmentPoint.append("y_position", point.y)
                        formDataAttachmentPoint.append("z_index", point.z)
                        formDataAttachmentPoint.append("z_index_back", point.zback ? point.zback : 0)
                        formDataAttachmentPoint.append("asset_type", point.assettype.pk)
                        Post(createAttachmentPoint(val5.data.data.pk), formDataAttachmentPoint).then(val6 => {
                          if (val6.status && val6.status < 300) {
                            setInfoMessage("Avatar Updated")
                            //Get updated avatar
                            getAvatarDetailsAndConvert()
                          } else {
                            if (val6.status === 401) {
                              navigator("/login")
                            }
                            else {
                              //handle errors
                              var temp = Object.keys(val6.data.form.errors)[0]
                              setErrorMessage(`${temp}: ${val6.data.form.errors[temp]}`)
                              setIsLoading(false)
                            }
                          }
                        })
                      })
                    }
                  } else {
                    if (val5.status === 401) {
                      navigator("/login")
                    }
                    else {
                      //handle errors
                      var temp = Object.keys(val5.data.form.errors)[0]
                      setErrorMessage(`${temp}: ${val5.data.form.errors[temp]}`)
                      setIsLoading(false)
                    }
                  }
                })
              }

              //check if attachment point changed and update
              frame.attactmentpoint.forEach((point, index3) => {
                if (point.pk && assetTypeList &&
                  avatar.assets[key].assetvarient[index] &&
                  avatar.assets[key].assetvarient[index].assetvarientframes[index2] &&
                  avatar.assets[key].assetvarient[index].assetvarientframes[index2].attactmentpoint[index3] &&
                  (
                    point.name !== avatar.assets[key].assetvarient[index].assetvarientframes[index2].attactmentpoint[index3].name ||
                    point.x !== avatar.assets[key].assetvarient[index].assetvarientframes[index2].attactmentpoint[index3].x ||
                    point.y !== avatar.assets[key].assetvarient[index].assetvarientframes[index2].attactmentpoint[index3].y ||
                    point.z !== avatar.assets[key].assetvarient[index].assetvarientframes[index2].attactmentpoint[index3].z ||
                    point.zback !== avatar.assets[key].assetvarient[index].assetvarientframes[index2].attactmentpoint[index3].zback
                  )
                ) {
                  setIsLoading(true)
                  const formDataAttachmentPoint = new FormData();
                  formDataAttachmentPoint.append("name", point.name)
                  formDataAttachmentPoint.append("x_position", point.x)
                  formDataAttachmentPoint.append("y_position", point.y)
                  formDataAttachmentPoint.append("z_index", point.z)
                  formDataAttachmentPoint.append("z_index_back", point.zback ? point.zback : 0)
                  formDataAttachmentPoint.append("asset_type", point.assettype.pk)
                  Post(updateAttachmentPoint(point.pk), formDataAttachmentPoint).then(val => {
                    if (val.status && val.status < 300) {
                      if (val.data && val.data.data) {
                        setInfoMessage("Avatar Updated")
                        //Get updated avatar
                        getAvatarDetailsAndConvert()
                      }
                    } else {
                      if (val.status === 401) {
                        navigator("/login")
                      }
                      else {
                        //handle errors
                        var temp = Object.keys(val.data.form.errors)[0]
                        setErrorMessage(val.data.form.errors[temp])
                        setIsLoading(false)
                      }
                    }
                  })
                }
                else if (!point.pk && frame.pk) {
                  //if new attachment point added
                  const formDataAttachmentPoint = new FormData();
                  formDataAttachmentPoint.append("name", point.name)
                  formDataAttachmentPoint.append("x_position", point.x)
                  formDataAttachmentPoint.append("y_position", point.y)
                  formDataAttachmentPoint.append("z_index", point.z)
                  formDataAttachmentPoint.append("z_index_back", point.zback ? point.zback : 0)
                  formDataAttachmentPoint.append("asset_type", point.assettype.pk)
                  Post(createAttachmentPoint(frame.pk), formDataAttachmentPoint).then(val6 => {
                    if (val6.status && val6.status < 300) {
                      setInfoMessage("Avatar Updated")
                      //Get updated avatar
                      getAvatarDetailsAndConvert()
                    } else {
                      if (val6.status === 401) {
                        navigator("/login")
                      }
                      else {
                        //handle errors
                        var temp = Object.keys(val6.data.form.errors)[0]
                        setErrorMessage(val6.data.form.errors[temp])
                        setIsLoading(false)
                      }
                    }
                  })
                }
              })
            })
          }
        })
      }
    })
  }

  return !isLoading ? (
    <div className="edit-avatar">
      {infoMessage !== "" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {infoMessage}
        </Alert>
      )}
      {errorMessage !== "" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}
      {noAvatarFound ? (
        <div>No Avatar found
          <Button onClick={() => {
            navigator("/avatarmanagement")
          }}>
            Go to Avatar List
          </Button>
        </div>
      ) : (
        <>
          <Topbar
            title="Edit Avatar"
            secondaryButtonTitle="Delete"
            handleSecondaryButton={handleDeleteAvatar}
            searchbar={false}
          />
          <AvatarForm
            avatar={avatar}
            updateAvatar={
              (newAvatar, thumbnail, images, assetTypeList, avatarStateList) =>
                updateAvatar(newAvatar, thumbnail, images, assetTypeList, avatarStateList)
            }
          />
        </>
      )}
    </div>
  ) : (
    <div>Loading...</div>
  );
}
