import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import useAvatarDisplay from "../hooks/useAvatarDisplay";

/**
 * Resume audio capture if it somehow gets lost.
 * @returns {JSX.Element}
 */
export default function ResumeAudioContext(): JSX.Element {
  const audioContext = useAvatarDisplay((state) => state.audioContext);
  const [audioContextState, setAudioContextState] = useState<
    "suspended" | "running" | "closed"
  >("suspended");

  /**
   * Add event listener and set the initial state of audioContext.
   */
  useEffect(() => {
    audioContext.onstatechange = (e) => {
      if (e.currentTarget)
        setAudioContextState((e.currentTarget as AudioContext).state);
    };

    setAudioContextState(audioContext.state);
  }, [audioContext]);

  const handleResumeAudioContext = () => {
    audioContext.resume();
    setAudioContextState("running");
  };

  return audioContextState === "suspended" ? (
    <Button onClick={handleResumeAudioContext}>Enable Microphone</Button>
  ) : (
    <></>
  );
}
