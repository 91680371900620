import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Get from "../../../utility/Get";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getOrgAvatarList } from "../../../utility/Endpoints";

interface AvatarListProps {
  search?: string;
}

/**
 * Displays a list of all avatars from the database
 * @returns
 */
export default function AvatarList(props: AvatarListProps): JSX.Element {
  let navigator = useNavigate();
  const [avatars, setAvatars] = useState<
    {
      avatar_type: object;
      name: string;
      image: string;
      pk: number;
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAvatars();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.search === "") {
      getAvatars();
    } else {
      //search by name
      getAvatars(true);
    }
    // eslint-disable-next-line
  }, [props.search]);

  function getAvatars(filter = false) {
    setIsLoading(true);
    var organization = { pk: 0 };
    if (localStorage.getItem("organization")) {
      organization = JSON.parse(localStorage.getItem("organization") ?? "");
    }
    Get(getOrgAvatarList(organization.pk)).then((val) => {
      if (val.status && val.status < 300) {
        if (filter) {
          setAvatars(
            val.data.data.filter((a: { name: string | string[] }) =>
              a.name.includes(props.search ?? "")
            )
          );
        } else {
          setAvatars(val.data.data);
        }
        setIsLoading(false);
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    });
  }

  return !isLoading ? (
    <div className="avatar-list">
      {avatars.length === 0 ? (
        <div>No avatars found.</div>
      ) : (
        <TableContainer sx={{ display: "flex", justifyContent: "center" }}>
          <Table style={{ tableLayout: "fixed", whiteSpace: "nowrap" }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Thumbnail Image</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {avatars.map((data, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      <Link href={`/avatarmanagement/${data.pk}/edit`}>
                        {data.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Box
                        component={"img"}
                        sx={{
                          width: 350,
                          maxWidth: { xs: "100%", md: 250 },
                        }}
                        alt={`${data.name} avatar`}
                        src={data.image}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  ) : (
    <div>Loading...</div>
  );
}
