import { useEffect, useState } from "react";
import vad from "voice-activity-detection";
import useAvatarDisplay from "../hooks/useAvatarDisplay";
import ResumeAudioContext from "./ResumeAudioContext";
import { Button, Slider } from "@mui/material";
import { Modal } from "../../../components/Modal";
import { useLocation } from "react-router";

// Note: Media Constraints can be found here:
// https://www.w3.org/TR/mediacapture-streams/?ref=deconstruct#def-constraint-latency

interface MicrophoneProps {
  showThreshold?: boolean;
  threshold?: number;
  absolutePositioning?: boolean;
  setThreshold?: (value: number) => void;
  isUsingModal: boolean;
}

/**
 * Handles everything microphone related.
 * @param {MicrophoneProps}
 * @returns {JSX.Element}
 */
export default function Microphone({
  showThreshold = false,
  threshold = 0.23,
  absolutePositioning = false,
  setThreshold,
  isUsingModal = false,
}: MicrophoneProps): JSX.Element {
  const audioContextRef = useAvatarDisplay((state) => state.audioContext);
  const setTalking = useAvatarDisplay((state) => state.setTalking);
  const setUsingMic = useAvatarDisplay((state) => state.setUsingMic);
  const [volume, setVolume] = useState<number>(0);
  const isUsingMic = useAvatarDisplay((state) => state.isUsingMic);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const location = useLocation();

  /**
   * This useEffect handles audio capture and state updating
   * for isTalking {boolean}. Call useStore((state) => state.isTalking)
   * to get the value from this logic.
   */
  useEffect(() => {
    try {
      navigator.mediaDevices
        .getUserMedia({
          audio: {
            autoGainControl: false,
            noiseSuppression: false,
            echoCancellation: false,
          },
        })
        .then((stream) => {
          let options = {
            fftSize: 8192,
            bufferLen: 0,
            smoothingTimeConstant: 0.2,
            minCaptureFreq: 85, // in Hz
            maxCaptureFreq: 255, // in Hz
            noiseCaptureDuration: 0, // in ms
            minNoiseLevel: 0, // from 0 to 1
            maxNoiseLevel: 1, // from 0 to 1
            avgNoiseMultiplier: 1,
            onUpdate: (value: number) => {
              if (setVolume) setVolume(value);
              if (value >= threshold) {
                setTalking(true);
              } else {
                setTalking(false);
              }
            },
          };
          setUsingMic(true);
          vad(audioContextRef, stream, options);
        })
        .catch((e) => {
          console.warn(
            "Could not connect microphone. Possibly rejected by user or blocked by broswer."
          );
          setUsingMic(false);
        });
    } catch (e) {
      console.warn("Microphone input no supported by the browser.");
      setUsingMic(false);
    }

    return () => {
      if (!["/", "/display"].includes(location.pathname))
        audioContextRef.suspend();
    };
  }, [
    audioContextRef,
    setTalking,
    setUsingMic,
    setVolume,
    threshold,
    location,
  ]);

  return (
    <div className="microphone">
      {isUsingModal && (
        <Button
          onClick={() => {
            setModalOpen(true);
            if (audioContextRef.state === "suspended") {
              audioContextRef.resume();
            }
          }}
        >
          Advanced Mic Settings
        </Button>
      )}
      {isUsingMic && !isUsingModal && (
        <div
          className={
            absolutePositioning
              ? "microphone__audio-context microphone__audio-context--abs"
              : "microphone__audio-context"
          }
        >
          <ResumeAudioContext />
        </div>
      )}
      {!isUsingMic && (
        <div
          className={
            absolutePositioning
              ? "microphone__allow microphone__allow--abs"
              : "microphone__allow"
          }
        >
          Please allow permissions to your microphone for full functionality
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setModalOpen(false);
          if (audioContextRef.state === "running") {
            audioContextRef.suspend();
          }
        }}
        title="Advanced Settings"
      >
        <>
          {showThreshold && isUsingModal && (
            <div className="microphone__slider" style={{ marginTop: ".8rem" }}>
              <label>Microphone Sensitivity</label>
              <Slider
                valueLabelDisplay="auto"
                min={0}
                max={1}
                defaultValue={threshold}
                value={threshold}
                step={0.01}
                onChange={(event) => {
                  if (event.target && setThreshold) {
                    // Threshold goes here
                    setThreshold(
                      parseFloat((event.target as HTMLInputElement).value)
                    );
                  }
                }}
              />
              <div
                className="microphone__ghost"
                style={{ width: `${(volume ?? 0) * 100}%` }}
              />
            </div>
          )}
          <div style={{ width: "100%", textAlign: "center" }}>
            {isUsingMic && isModalOpen && (
              <div
                className={
                  absolutePositioning
                    ? "microphone__audio-context microphone__audio-context--abs"
                    : "microphone__audio-context"
                }
              >
                <ResumeAudioContext />
              </div>
            )}
            <Button
              onClick={() => {
                if (audioContextRef.state === "running") {
                  audioContextRef.suspend();
                }
                setModalOpen(false);
              }}
              variant="contained"
            >
              Close
            </Button>
          </div>
        </>
      </Modal>
    </div>
  );
}
