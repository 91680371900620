import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";

import { PrivateRoute } from "./utility/PrivateRoute";

import Authentication from "./features/Authentication/Authentication";
import UserManagement from "./features/Admin/Users/UserManagement";
import AvatarManagement from "./features/Admin/Avatars/AvatarManagement";
import BackgroundManagement from "./features/Admin/Backgrounds/BackgroundManagement";
import EmoteManagement from "./features/Admin/Emotes/EmoteManagement";

import "./styles/index.scss";
import EditUser from "./features/Admin/Users/EditUser";
import EditAvatar from "./features/Admin/Avatars/EditAvatar";
import EditBackground from "./features/Admin/Backgrounds/EditBackground";
import EditEmote from "./features/Admin/Emotes/EditEmote";
import Dashboard from "./features/User/Dashboard";
import Account from "./features/Account/Account";
import { useEffect, useState } from "react";
import { getPreedictionOrganization } from "./utility/Endpoints";
import Get from "./utility/Get";
import AddAvatarManager from "./features/Admin/Avatars/AddAvatar/AddAvatarManager";
import DisplayWindow from "./features/User/DisplayWindow";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import FAQ from "./features/FAQ/Faq";

/* Material UI theme settings */
const theme = createTheme({
  palette: {
    primary: { main: "#fc683f" },
    secondary: { main: "#000" },
    error: { main: "#cb3d40" },
  },
  shape: {
    borderRadius: "8px",
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

/**
 * Start of the app
 */
function App(): JSX.Element {
  // user object obtained from backend or local
  const [user, setUser] = useState<any>();
  const isProduction = process.env.NODE_ENV === "production";

  useEffect(() => {
    Get(getPreedictionOrganization()).then((val) => {
      if (val.status && val.status < 300) {
        if (val.data && val.data.data) {
          localStorage.setItem("organization", JSON.stringify(val.data.data));
        }
      }
    });

    //if we still have session id and user object, save user
    if (
      localStorage.getItem("sessionid") &&
      localStorage.getItem("vstreamer_user")
    ) {
      setUser(JSON.parse(localStorage.getItem("vstreamer_user") ?? ""));
    }
  }, []);

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<div>Loading...</div>} //If not pass, nothing appears at the time of new version check.
    >
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route
              path="/login"
              element={<Authentication setUser={(u) => setUser(u)} />}
            />
            <Route path="*" element={<div>Page not found.</div>} />

            {/* Need to have start path here. Private route will redirect to login if no user  */}
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/" element={<Dashboard />} />
            </Route>

            <Route path="/faq" element={<FAQ />}></Route>

            {user && (
              <>
                {/* Global Routes */}
                <>
                  <Route path="/account" element={<PrivateRoute />}>
                    <Route path="/account" element={<Account user={user} />} />
                  </Route>

                  <Route path="/display" element={<PrivateRoute />}>
                    <Route path="/display" element={<DisplayWindow />} />
                  </Route>
                </>

                {/* Management Routes */}
                {(user.is_user_manager || user.is_organization_manager) && (
                  <>
                    <Route path="/" element={<PrivateRoute />}>
                      <Route
                        path="/usermanagement"
                        element={<UserManagement />}
                      />
                    </Route>
                    <Route path="/avatarmanagement" element={<PrivateRoute />}>
                      <Route
                        path="/avatarmanagement"
                        element={<AvatarManagement />}
                      />
                    </Route>
                    <Route path="/addavatar" element={<PrivateRoute />}>
                      <Route path="/addavatar" element={<AddAvatarManager />} />
                    </Route>
                    <Route
                      path="/backgroundmanagement"
                      element={<PrivateRoute />}
                    >
                      <Route
                        path="/backgroundmanagement"
                        element={<BackgroundManagement />}
                      />
                    </Route>
                    <Route path="/emotemanagement" element={<PrivateRoute />}>
                      <Route
                        path="/emotemanagement"
                        element={<EmoteManagement />}
                      />
                    </Route>
                    {/* Edit Routes */}
                    <Route path="/:id/edit" element={<PrivateRoute />}>
                      <Route path="/:id/edit" element={<EditUser />} />
                    </Route>
                    <Route
                      path="/avatarmanagement/:id/edit"
                      element={<PrivateRoute />}
                    >
                      <Route
                        path="/avatarmanagement/:id/edit"
                        element={<EditAvatar />}
                      />
                    </Route>
                    <Route
                      path="/backgroundmanagement/:id/edit"
                      element={<PrivateRoute />}
                    >
                      <Route
                        path="/backgroundmanagement/:id/edit"
                        element={<EditBackground />}
                      />
                    </Route>
                    <Route
                      path="/emotemanagement/:id/edit"
                      element={<PrivateRoute />}
                    >
                      <Route
                        path="/emotemanagement/:id/edit"
                        element={<EditEmote />}
                      />
                    </Route>
                  </>
                )}
              </>
            )}
          </Routes>
        </ThemeProvider>
      </Router>
    </CacheBuster>
  );
}

export default App;
