import { arrayToTree } from "performant-array-to-tree";
import { useEffect, useState } from "react";
import {
  AssetStateObject,
  ExpressionsListType,
  Tree,
} from "../../../utility/Types";
import useAvatarDisplay from "../hooks/useAvatarDisplay";

const expressions = [
  "happy",
  "sad",
  "neutral",
  "angry",
  "disguested",
  "fearful",
  "surprised",
];

/**
 * Preloads all assets and sets up state for avatar and emotes
 * @returns {JSX.Element}
 */
export default function Preload(): JSX.Element {
  const stream = useAvatarDisplay((state) => state.stream);
  const setStream = useAvatarDisplay((state) => state.setStream);
  const setEmotes = useAvatarDisplay((state) => state.setEmotes);
  const [preloadList, setPreloadList] = useState<Array<string>>([]);
  const setWindowSize = useAvatarDisplay((state) => state.setWindowSize);
  const setHotkeyPressed = useAvatarDisplay((state) => state.setHotkeyPressed);
  const setAvailableExpressions = useAvatarDisplay(
    (state) => state.setAvailableExpressions
  );
  const setHotkeyHeld = useAvatarDisplay((state) => state.setHotkeyHeld);
  const hotkeyPressed = useAvatarDisplay((state) => state.hotkeyPressed);
  const setShowEmotes = useAvatarDisplay((state) => state.setShowEmotes);
  const setBackground = useAvatarDisplay((state) => state.setBackground);

  /**
   * Convert data into a tree
   */
  useEffect(() => {
    const avatarStream = JSON.parse(
      localStorage.getItem("avatar-stream") as string
    );

    setShowEmotes(avatarStream.showEmotes);
    setBackground(avatarStream.background ?? undefined);

    // Get list of expressions for this character here
    const expressionsIncluded: ExpressionsListType = [];
    const expressionStream = JSON.parse(
      localStorage.getItem("avatar-stream") as string
    ).avatar.avatar_assets;
    for (const { asset } of expressionStream) {
      for (const { avatar_state_list } of asset.asset_varients) {
        if (
          avatar_state_list.find(
            (x: AssetStateObject) => x.name === "expression"
          )
        ) {
          for (const { name } of avatar_state_list) {
            if (
              expressions.includes(name) &&
              !expressionsIncluded.includes(name)
            ) {
              expressionsIncluded.push(name);
            }
          }
        }
      }
    }
    setAvailableExpressions(expressionsIncluded);

    // Map data into tree
    const tree = arrayToTree(avatarStream.avatar.avatar_assets, {
      id: "asset.asset_type.pk",
      parentId: "asset.parent_asset_type.pk",
    });
    avatarStream.avatar = tree[0];

    setStream(avatarStream);
  }, [setStream, setAvailableExpressions, setShowEmotes, setBackground]);

  useEffect(() => {
    if (stream?.emotes) {
      for (const emote of stream?.emotes) {
        if (emote.image) setPreloadList((prev) => [...prev, emote.image]);
        if (emote.image_back)
          setPreloadList((prev) => [...prev, emote.image_back as string]);
      }
    }

    fillPreloadData(stream?.avatar);
    if (stream && stream.emotes.length > 0) {
      // Modify the emotes to include hotkey
      const newEmotes = stream.emotes.map((prev, i) => ({
        ...prev,
        hotkey: (i + 1).toString(),
      }));
      setEmotes(newEmotes);
    }

    function fillPreloadData(inputStream: Tree | undefined) {
      if (inputStream !== undefined && inputStream.data.asset.asset_varients) {
        if (inputStream.children.length > 0) {
          for (const child of inputStream.children) {
            // Load any child data
            fillPreloadData(child);
          }
        }

        // Grab each frame for caching
        for (const variant of inputStream.data.asset.asset_varients) {
          for (const frame of variant.frames) {
            if (frame.image) setPreloadList((prev) => [...prev, frame.image]);
            if (frame.image_back)
              setPreloadList((prev) => [...prev, frame.image_back as string]);
          }
        }
      }
    }
  }, [stream, setPreloadList, setEmotes]);

  useEffect(() => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    const handleResize = (e: Event) => {
      const target = e.target as Window;
      setWindowSize({ width: target.innerWidth, height: target.innerHeight });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWindowSize]);

  /**
   * Setup hotkey listener
   */
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (!hotkeyPressed) {
        setHotkeyPressed(e.key);
        setHotkeyHeld(true);
      }
    };

    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [setHotkeyPressed, setHotkeyHeld, hotkeyPressed]);

  useEffect(() => {
    const handleKeyup = (e: KeyboardEvent) => {
      if (hotkeyPressed === e.key) {
        setHotkeyHeld(false);
        setHotkeyPressed(null);
      }
    };

    document.addEventListener("keyup", handleKeyup);

    return () => {
      document.removeEventListener("keyup", handleKeyup);
    };
  }, [setHotkeyHeld, setHotkeyPressed, hotkeyPressed]);

  return (
    <div className="preload">
      {preloadList.map((image, i) => (
        <img src={image} alt="preload" key={i} />
      ))}
    </div>
  );
}
