import { Button, Typography } from "@mui/material";
import { useState } from "react";
import Topbar from "../../../../components/Topbar";
import AvatarForm from "./AvatarForm";
import AvatarTypeList from "./AvatarTypeList";
import EditAvatarType from "./EditAvatarType";

type AvatarTypeObject = {
  pk: number;
  name: string;
  visible: boolean;
  enabled: boolean;
};

/**
 * Contains all Avatar related components
 * @returns
 */
export default function AddAvatarManager(): JSX.Element {
  const [page, setPage] = useState(0);
  const [avatarType, setType] = useState();
  const [openEditTypeModal, setOpenEditTypeModal] = useState(false);
  const [typeList, setTypeList] = useState<Array<AvatarTypeObject>>([]);

  return (
    <div className="avatar-management">
      <Topbar title="Add Avatar" searchbar={false} />
      {
        {
          0: (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2>Select Avatar Type</h2>
                <Button
                  variant="contained"
                  onClick={() => setOpenEditTypeModal(true)}
                >
                  <Typography color="white">Add Avatar Type</Typography>
                </Button>
              </div>
              <AvatarTypeList
                typeSelected={(type) => {
                  setPage(1);
                  setType(type);
                }}
                typeList={typeList}
              />
              <EditAvatarType
                openEditTypeModal={openEditTypeModal}
                setOpenEditTypeModal={setOpenEditTypeModal}
                updateAvatarTypeList={(list) => setTypeList(list)}
              />
            </>
          ),
          1: (
            <>
              <h2>Upload Avatar Form</h2>
              <AvatarForm avatarType={avatarType} />
            </>
          ),
        }[page]
      }
    </div>
  );
}
