import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  createAssetType,
  deleteAssetType,
  updateAssetType,
} from "../../../../utility/Endpoints";
import Post from "../../../../utility/Post";

const style = {
  backgroundColor: "white",
  padding: "1.5rem",
  borderRadius: "8px",
  width: "60vw",
  height: "80vh",
  overflow: "auto",
};

type AssetTypeObject = {
  enabled: boolean;
  name: string;
  parent_asset_type_id: number | null;
  pk: number;
  tiltable: boolean;
  visible: boolean;
};

interface EditAssetTypeProps {
  isOpen: boolean;
  handleClose(): any;
  assetTypeList: Array<AssetTypeObject>;
  avatarTypeName?: string;
  avatarTypePK: number;
  updateAvatarList?: (newList: Array<AssetTypeObject>, pk: number) => void;
}

/**
 * Add an Avatar to the database
 * @param props
 * @returns
 */
export default function EditAssetType(props: EditAssetTypeProps): JSX.Element {
  let navigator = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [assetTypeList, setAssetTypeList] = useState<Array<AssetTypeObject>>(
    []
  );
  const [newAssetType, setNewAssetType] = useState<AssetTypeObject>({
    name: "",
    enabled: false,
    parent_asset_type_id: null,
    pk: 0,
    tiltable: false,
    visible: false,
  });

  useEffect(() => {
    setAssetTypeList(props.assetTypeList);
  }, [props]);

  function handleUpdateType(index: number) {
    setErrorMessage("");
    setInfoMessage("");
    setIsLoading(true);
    const formDataUpdate = new FormData();
    formDataUpdate.append(
      "enabled",
      assetTypeList[index].enabled ? "true" : "false"
    );
    formDataUpdate.append(
      "visible",
      assetTypeList[index].visible ? "true" : "false"
    );
    formDataUpdate.append(
      "tiltable",
      assetTypeList[index].tiltable ? "true" : "false"
    );
    formDataUpdate.append("name", assetTypeList[index].name);
    formDataUpdate.append("priority", "0");
    formDataUpdate.append("avatar_type", props.avatarTypePK.toString());
    const temp = assetTypeList[index].parent_asset_type_id;
    if (temp) {
      formDataUpdate.append("parent_asset_type", temp.toString());
    }
    Post(updateAssetType(assetTypeList[index].pk), formDataUpdate).then(
      (val) => {
        if (val.status && val.status < 300) {
          setInfoMessage("Asset type updated.");
          //update parent
          if (props.updateAvatarList) {
            props.updateAvatarList(assetTypeList, props.avatarTypePK);
          }
        } else {
          if (val.status === 401) {
            navigator("/login");
          } else {
            //handle errors
            var temp = Object.keys(val.data.form.errors)[0];
            setErrorMessage(val.data.form.errors[temp]);
          }
        }
        setIsLoading(false);
      }
    );
  }

  function handleDelete(pk: number) {
    setErrorMessage("");
    setInfoMessage("");
    const deleteForm = new FormData();
    setIsLoading(true);
    Post(deleteAssetType(pk), deleteForm).then((val) => {
      if (val.status && val.status < 300) {
        setInfoMessage("Asset type deleted.");
        setAssetTypeList(assetTypeList.filter((x) => x.pk !== pk));
        // update parent
        if (props.updateAvatarList) {
          props.updateAvatarList(
            assetTypeList.filter((x) => x.pk !== pk),
            props.avatarTypePK
          );
        }
      } else {
        if (val.status === 401) {
          navigator("/login");
        } else {
          //handle errors
          var temp = Object.keys(val.data.form.errors)[0];
          setErrorMessage(val.data.form.errors[temp]);
        }
      }
      setIsLoading(false);
    });
  }

  function createType() {
    setErrorMessage("");
    setInfoMessage("");
    //use new type
    setIsLoading(true);
    const formDataCreate = new FormData();
    formDataCreate.append("enabled", newAssetType.enabled ? "true" : "false");
    formDataCreate.append("visible", newAssetType.visible ? "true" : "false");
    formDataCreate.append("tiltable", newAssetType.tiltable ? "true" : "false");
    if (newAssetType.parent_asset_type_id) {
      formDataCreate.append(
        "parent_asset_type",
        newAssetType.parent_asset_type_id.toString()
      );
    }
    formDataCreate.append("placement", "body") //TODO update this later
    formDataCreate.append("name", newAssetType.name);
    formDataCreate.append("priority", "0");
    Post(createAssetType(props.avatarTypePK), formDataCreate).then((val) => {
      if (val.status && val.status < 300) {
        setInfoMessage("Asset type created.");
        setAssetTypeList((prev) => [...prev, val.data.data]);
        // update parent
        if (props.updateAvatarList) {
          props.updateAvatarList(
            assetTypeList.concat(val.data.data),
            props.avatarTypePK
          );
        }
        setNewAssetType({
          name: "",
          enabled: false,
          parent_asset_type_id: null,
          pk: 0,
          tiltable: false,
          visible: false,
        });
      } else {
        if (val.status === 401) {
          navigator("/login");
        } else {
          //handle errors
          var temp = Object.keys(val.data.form.errors)[0];
          setErrorMessage(val.data.form.errors[temp]);
        }
      }
      setIsLoading(false);
    });
  }

  function handleNewTypeChange(e: React.ChangeEvent<HTMLInputElement>) {
    setNewAssetType({ ...newAssetType, [e.target.name]: e.target.value });
  }

  function handleNewTypeCheck(e: React.ChangeEvent<HTMLInputElement>) {
    setNewAssetType({ ...newAssetType, [e.target.name]: e.target.checked });
  }

  function handleNewTypeSelected(event: any) {
    const {
      target: { value, name },
    } = event;
    setNewAssetType({ ...newAssetType, [name]: value });
  }

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) {
    setAssetTypeList((prev) => {
      const newtype = prev.map((obj, i) => {
        if (i === index) {
          return { ...obj, [e.target.name]: e.target.value };
        }
        return obj;
      });
      return newtype;
    });
  }

  function handleCheck(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    setAssetTypeList((prev) => {
      const newtype = prev.map((obj, i) => {
        if (i === index) {
          return { ...obj, [e.target.name]: e.target.checked };
        }
        return obj;
      });
      return newtype;
    });
  }

  const handleSelected = (event: any, index: number) => {
    const {
      target: { value, name },
    } = event;
    setAssetTypeList((prev) => {
      const newtype = prev.map((obj, i) => {
        if (i === index) {
          return { ...obj, [name]: value };
        }
        return obj;
      });
      return newtype;
    });
  };

  return !isLoading ? (
    <Modal open={props.isOpen} onClose={props.handleClose}>
      <Box style={style}>
        {infoMessage !== "" && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {infoMessage}
          </Alert>
        )}
        {errorMessage !== "" && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        <div>
          <div>
            Edit {props.avatarTypeName ? props.avatarTypeName : ""} Asset Types
          </div>
          <TableContainer sx={{ width: "100%" }}>
            <Table sx={{ tableLayout: "fixed", width: "auto" }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: "100px" }}>Name</TableCell>
                  <TableCell style={{ minWidth: "100px" }}>
                    Parent Asset Type
                  </TableCell>
                  <TableCell>Enabled</TableCell>
                  <TableCell>Visible</TableCell>
                  <TableCell>Tiltable</TableCell>
                  <TableCell>Create/Update</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={0}>
                  <TableCell>
                    <TextField
                      fullWidth
                      label="Name"
                      required
                      value={newAssetType.name}
                      name="name"
                      onChange={handleNewTypeChange}
                    />
                  </TableCell>
                  <TableCell>
                    <FormControl>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Parent Asset Type
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        value={newAssetType.parent_asset_type_id}
                        name={"parent_asset_type_id"}
                        onChange={(e) => handleNewTypeSelected(e)}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => {
                          return assetTypeList.filter((x) => x.pk === selected)
                            .length > 0
                            ? assetTypeList.filter((x) => x.pk === selected)[0]
                                .name
                            : "";
                        }}
                        sx={{ minWidth: "100px" }}
                      >
                        {/* list all other asset types, not the current one. aka body cannot be parent to body  */}
                        {assetTypeList.map((assetType, index) => (
                          <MenuItem key={index} value={assetType.pk}>
                            <ListItemText primary={assetType.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="enabled"
                            checked={newAssetType.enabled}
                            onChange={handleNewTypeCheck}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Enabled"
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="visible"
                            checked={newAssetType.visible}
                            onChange={handleNewTypeCheck}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Visible"
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="tiltable"
                            checked={newAssetType.tiltable}
                            onChange={handleNewTypeCheck}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Tiltable"
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      sx={{ my: 1 }}
                      onClick={() => createType()}
                    >
                      <Typography color="white">Create Asset Type</Typography>
                    </Button>
                  </TableCell>
                </TableRow>
                {assetTypeList.map((data, i) => {
                  return (
                    <>
                      <TableRow key={i}>
                        <TableCell>
                          <TextField
                            fullWidth
                            label="Name"
                            required
                            value={data.name}
                            name="name"
                            onChange={(e) => handleChange(e, i)}
                          />
                        </TableCell>
                        <TableCell>
                          <FormControl>
                            <InputLabel id="demo-multiple-checkbox-label">
                              Parent Asset Type
                            </InputLabel>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              value={assetTypeList[i].parent_asset_type_id}
                              name={"parent_asset_type_id"}
                              onChange={(e) => handleSelected(e, i)}
                              input={<OutlinedInput label="Tag" />}
                              renderValue={(selected) => {
                                return assetTypeList.filter(
                                  (x) => x.pk === selected
                                ).length > 0
                                  ? assetTypeList.filter(
                                      (x) => x.pk === selected
                                    )[0].name
                                  : "";
                              }}
                              sx={{ minWidth: "100px" }}
                            >
                              {/* list all other asset types, not the current one. aka body cannot be parent to body  */}
                              {assetTypeList
                                .filter((x) => x.pk !== data.pk)
                                .map((assetType, index) => (
                                  <MenuItem key={index} value={assetType.pk}>
                                    <ListItemText primary={assetType.name} />
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="enabled"
                                  checked={assetTypeList[i].enabled}
                                  onChange={(e) => handleCheck(e, i)}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                              label="Enabled"
                            />
                          </FormGroup>
                        </TableCell>
                        <TableCell>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="visible"
                                  checked={assetTypeList[i].visible}
                                  onChange={(e) => handleCheck(e, i)}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                              label="Visible"
                            />
                          </FormGroup>
                        </TableCell>
                        <TableCell>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="tiltable"
                                  checked={assetTypeList[i].tiltable}
                                  onChange={(e) => handleCheck(e, i)}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                              label="Tiltable"
                            />
                          </FormGroup>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            sx={{
                              my: 1,
                            }}
                            onClick={() => handleUpdateType(i)}
                          >
                            <Typography color="white">
                              Update Asset Type
                            </Typography>
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            sx={{ my: 1 }}
                            onClick={() => handleDelete(assetTypeList[i].pk)}
                            color="error"
                          >
                            <Typography color="white">
                              Delete Asset Type
                            </Typography>
                          </Button>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            onClick={props.handleClose}
            variant="contained"
            sx={{ mx: 2, my: 2 }}
          >
            <Typography color="white">Cancel</Typography>
          </Button>
        </div>
      </Box>
    </Modal>
  ) : (
    <div>Loading...</div>
  );
}
