interface LogoProps {
  subtitle?: string;
  stacked?: boolean;
  showSubtitle?: boolean;
}

export default function Logo({
  subtitle,
  stacked,
  showSubtitle = true,
}: LogoProps) {
  return (
    <div className={stacked ? "logo logo__stacked" : "logo"}>
      {showSubtitle && (
        <div className={"logo__subtitle"}>
          <img
            className="logo__NE"
            src="/images/NeverEnding-Logo-Drawn.png"
            alt="beneverending logo"
          />
          <p style={{ margin: 0 }}>Presents</p>
        </div>
      )}
      <img
        className="logo__image"
        src="/images/preediction-logo.svg"
        alt="beneverending logo"
      />
      <div className="logo__subtitle">
        <span>{subtitle}</span>
      </div>
    </div>
  );
}
