import { Button } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Logo from "../../components/Logo";
import AdminNavigation from "./Admin/AdminNavigation";
import UserNavigation from "./User/UserNavigation";

/**
 * Contains all Navigation related components
 * @returns
 */
export default function Navigation(): JSX.Element {
  let navigator = useNavigate();
  const [user, setUser] = useState<any>();
  const classes = clsx("navigation");
  const { pathname } = useLocation();

  useEffect(() => {
    //if we still have session id and user object, save user
    if (
      localStorage.getItem("sessionid") &&
      localStorage.getItem("vstreamer_user")
    ) {
      setUser(JSON.parse(localStorage.getItem("vstreamer_user") ?? ""));
    }
  }, []);

  function handleLogout() {
    localStorage.removeItem("vstreamer_user");
    localStorage.removeItem("sessionid");
    localStorage.removeItem("avatar-stream");
    navigator("/login");
  }

  return (
    <div className={classes}>
      {pathname !== "/display" && (
        <>
          <Logo showSubtitle={false} />
          <div className="navbar">
            {user && (user.is_user_manager || user.is_organization_manager) && (
              <AdminNavigation />
            )}

            {user && !user.is_user_manager && !user.is_organization_manager && (
              <UserNavigation />
            )}

            {user ? (
              <Button variant="outlined" onClick={handleLogout}>
                Logout
              </Button>
            ) : (
              <Button
                variant="outlined"
                onClick={() => navigator("/login")}
                style={{ marginLeft: "auto" }}
              >
                Login
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
}
