import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SelectableButton from "../../../components/SelectableButton";
import Topbar from "../../../components/Topbar";
import {
  createAvatarUserConnection,
  createBackgroundUserConnection,
  createEmoteUserConnection,
  getOrgAvatarBackgrounds,
  getOrgAvatarList,
  getOrgEmoteList,
  getUserAvatarUserList,
  getUserAvatarBackgroundUserList,
  getUserEmoteUserList,
  removeAvatarUserConnection,
  removeBackgroundUserConnection,
  removeEmoteUserConnection,
  userBasics,
} from "../../../utility/Endpoints";
import Get from "../../../utility/Get";
import { useLocation, useNavigate } from "react-router";
import Post from "../../../utility/Post";

/**
 * Update a specific User on the database
 * @returns
 */
type AvatarObject = {
  avatar_assets: object[];
  avatar_type: object;
  image: string;
  name: string;
  pk: number; //avatar pk
};

type EmoteObject = {
  name: string;
  thumbnail_image: string;
  image: string;
  pk: number;
};

type BackgroundObject = {
  name: string;
  image: string;
  pk: number;
};

type AvatarUserObject = {
  avatar: AvatarObject;
  user: any;
  pk: number; //this is the avatar user pk for delete
};

type EmoteUserObject = {
  emote: EmoteObject;
  pk: number; //this is the emote user pk for delete
  user: any;
};

type BackgroundUserObject = {
  avatar_background: BackgroundObject;
  pk: number; //this is the avatar_background pk for delete
  user: any;
};

export default function EditUser(): JSX.Element {
  let navigator = useNavigate();
  const location = useLocation();
  const [userInfo, setUserInfo] = useState<{
    email: string;
    pk: number;
    username: string;
    is_organization_manager: boolean;
    is_user_manager: boolean;
    is_active: boolean;
  }>({
    email: "",
    username: "",
    pk: 0,
    is_organization_manager: false,
    is_user_manager: false,
    is_active: false,
  });
  const [avatars, setAvatars] = useState<AvatarObject[]>([]);
  const [emotes, setEmotes] = useState<EmoteObject[]>([]);
  const [backgrounds, setBackgrounds] = useState<BackgroundObject[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedAvatars, setSelectedAvatars] = useState<
    Array<AvatarUserObject>
  >([]);
  const [selectedEmotes, setSelectedEmotes] = useState<Array<EmoteUserObject>>(
    []
  );
  const [selectedBackgrounds, setSelectedBackgrounds] = useState<
    Array<BackgroundUserObject>
  >([]);
  const [deleteAvatars, setDeleteAvatars] = useState<Array<AvatarUserObject>>(
    []
  );
  const [deleteEmotes, setDeleteEmotes] = useState<Array<EmoteUserObject>>([]);
  const [deleteBackgrounds, setDeleteBackgrounds] = useState<
    Array<BackgroundUserObject>
  >([]);

  useEffect(() => {
    GetUserInfo();
    // eslint-disable-next-line
  }, []);

  function GetUserInfo() {
    setIsLoading(true);
    let user_pk = location.pathname.split("/");
    //Get user basic info
    Get(userBasics(user_pk[1])).then((val) => {
      if (val.status && val.status < 300) {
        setUserInfo(val.data.form.data);
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
      setIsLoading(false);
    });
    //get all avatars
    var organization = { pk: 0 };
    if (localStorage.getItem("organization")) {
      organization = JSON.parse(localStorage.getItem("organization") ?? "");
    }
    Get(getOrgAvatarList(organization.pk)).then((val) => {
      if (val.status && val.status < 300) {
        setAvatars(val.data.data);
        // setIsLoading(false)
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    });
    //get user's avatars
    Get(getUserAvatarUserList(user_pk[1])).then((val) => {
      if (val.status && val.status < 300) {
        setSelectedAvatars(val.data.data);
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    });

    //get all emotes
    Get(getOrgEmoteList(organization.pk)).then((val) => {
      if (val.status && val.status < 300) {
        setEmotes(val.data.data);
        setIsLoading(false);
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    });
    //get user's emotes
    Get(getUserEmoteUserList(user_pk[1])).then((val) => {
      if (val.status && val.status < 300) {
        setSelectedEmotes(val.data.data);
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    });

    //get all backgrounds
    Get(getOrgAvatarBackgrounds(organization.pk)).then((val) => {
      if (val.status && val.status < 300) {
        setBackgrounds(val.data.data);
        setIsLoading(false);
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    });
    //get user's backgrounds
    Get(getUserAvatarBackgroundUserList(user_pk[1])).then((val) => {
      if (val.status && val.status < 300) {
        setSelectedBackgrounds(val.data.data);
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    });
  }

  /**
   * if in selected and clicked, add emote to delete AND remove emote from selected
   * else {
   *  if clicked and in deleted, Move from deleted to selected
   *  else add emote from all (item and pk:0) to selected
   * }
   */
  const handleSelectAvatars = (item: AvatarObject) => {
    if (selectedAvatars.some((cur) => cur.avatar.pk === item.pk)) {
      setDeleteAvatars(
        selectedAvatars.filter((cur) => cur.avatar.pk === item.pk)
      );
      setSelectedAvatars((prev) =>
        prev.filter((cur) => cur.avatar.pk !== item.pk)
      );
    } else {
      if (deleteAvatars.some((cur) => cur.avatar.pk === item.pk)) {
        setSelectedAvatars((prev) => [
          ...prev,
          deleteAvatars.filter((cur) => cur.avatar.pk === item.pk)[0],
        ]);
        setDeleteAvatars((prev) =>
          prev.filter((cur) => cur.avatar.pk !== item.pk)
        );
      } else {
        setSelectedAvatars((prev) => [
          ...prev,
          { avatar: item, pk: 0, user: {} },
        ]);
      }
    }
  };

  const handleSelectEmotes = (item: EmoteObject) => {
    if (selectedEmotes.some((cur) => cur.emote.pk === item.pk)) {
      setDeleteEmotes(selectedEmotes.filter((cur) => cur.emote.pk === item.pk));
      setSelectedEmotes((prev) =>
        prev.filter((cur) => cur.emote.pk !== item.pk)
      );
    } else {
      if (deleteEmotes.some((cur) => cur.emote.pk === item.pk)) {
        setSelectedEmotes((prev) => [
          ...prev,
          deleteEmotes.filter((cur) => cur.emote.pk === item.pk)[0],
        ]);
        setDeleteEmotes((prev) =>
          prev.filter((cur) => cur.emote.pk !== item.pk)
        );
      } else {
        setSelectedEmotes((prev) => [
          ...prev,
          { emote: item, pk: 0, user: {} },
        ]);
      }
    }
  };

  const handleSelectBackgrounds = (item: BackgroundObject) => {
    if (
      selectedBackgrounds.some((cur) => cur.avatar_background.pk === item.pk)
    ) {
      setDeleteBackgrounds(
        selectedBackgrounds.filter(
          (cur) => cur.avatar_background.pk === item.pk
        )
      );
      setSelectedBackgrounds((prev) =>
        prev.filter((cur) => cur.avatar_background.pk !== item.pk)
      );
    } else {
      if (
        deleteBackgrounds.some((cur) => cur.avatar_background.pk === item.pk)
      ) {
        setSelectedBackgrounds((prev) => [
          ...prev,
          deleteBackgrounds.filter(
            (cur) => cur.avatar_background.pk === item.pk
          )[0],
        ]);
        setDeleteBackgrounds((prev) =>
          prev.filter((cur) => cur.avatar_background.pk !== item.pk)
        );
      } else {
        setSelectedBackgrounds((prev) => [
          ...prev,
          { avatar_background: item, pk: 0, user: {} },
        ]);
      }
    }
  };

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  }

  function handleCheck(e: React.ChangeEvent<HTMLInputElement>) {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.checked });
  }

  function handleSave() {
    setErrorMessage("");
    setInfoMessage("");
    var error = false;
    if (userInfo.username === "" || userInfo.username.length === 0) {
      setUsernameError("This field cannot be empty.");
      error = true;
    } else {
      setUsernameError("");
    }
    if (userInfo.email === "" || userInfo.email.length === 0) {
      setEmailError("This field cannot be empty.");
      error = true;
    } else {
      setEmailError("");
    }

    if (!error) {
      setIsLoading(true);
      const FormData = require("form-data");
      const formData = new FormData();
      formData.append("username", userInfo.username);
      formData.append("email", userInfo.email);
      formData.append(
        "is_organization_manager",
        userInfo.is_organization_manager
      );
      formData.append("is_active", userInfo.is_active);
      formData.append("is_user_manager", userInfo.is_user_manager);
      let user_pk = location.pathname.split("/");
      const emptyFormData = new FormData();

      Post(userBasics(user_pk[1]), formData).then((val) => {
        if (val.status && val.status < 300) {
          setInfoMessage("User updated");
        } else {
          if (val.status === 401) {
            navigator("/login");
          }
          if (val.data && val.data.form && val.data.form.errors) {
            setErrorMessage(
              val.data.form.errors[Object.keys(val.data.form.errors)[0]]
            );
          }
        }
        setIsLoading(false);
      });

      //for each avatar in selected, create avatar user connection
      //for each avatar in not selected, delete avatar user connection
      //dido for emotes and backgrounds
      selectedAvatars.forEach((avatar) => {
        if (avatar.pk === 0) {
          //create emote_user
          const formDataAvatarUser = new FormData();
          formDataAvatarUser.append("user", user_pk[1]);
          Post(
            createAvatarUserConnection(avatar.avatar.pk),
            formDataAvatarUser
          ).then((val) => {
            if (val.status && val.status < 300) {
              setInfoMessage("User updated");
            } else {
              if (val.status === 401) {
                navigator("/login");
              }
              if (val.data && val.data.form && val.data.form.errors) {
                setErrorMessage(
                  val.data.form.errors[Object.keys(val.data.form.errors)[0]]
                );
              }
            }
          });
        } else {
          //do nothing cause emote_user already created
        }
      });
      deleteAvatars.forEach((avatar) => {
        // delete emote_user
        Post(removeAvatarUserConnection(avatar.pk), emptyFormData).then(
          (val) => {
            if (val.status && val.status < 300) {
              setInfoMessage("User updated");
            } else {
              if (val.status === 401) {
                navigator("/login");
              }
              if (val.data && val.data.form && val.data.form.errors) {
                setErrorMessage(
                  val.data.form.errors[Object.keys(val.data.form.errors)[0]]
                );
              }
            }
          }
        );
      });

      // add/delete emotes
      selectedEmotes.forEach((emote) => {
        if (emote.pk === 0) {
          //create emote_user
          const formDataEmoteUser = new FormData();
          formDataEmoteUser.append("user", user_pk[1]);
          Post(
            createEmoteUserConnection(emote.emote.pk),
            formDataEmoteUser
          ).then((val) => {
            if (val.status && val.status < 300) {
              setInfoMessage("User updated");
            } else {
              if (val.status === 401) {
                navigator("/login");
              }
              if (val.data && val.data.form && val.data.form.errors) {
                setErrorMessage(
                  val.data.form.errors[Object.keys(val.data.form.errors)[0]]
                );
              }
            }
          });
        } else {
          //do nothing cause emote_user already created
        }
      });
      deleteEmotes.forEach((emote) => {
        // delete emote_user
        Post(removeEmoteUserConnection(emote.pk), emptyFormData).then((val) => {
          if (val.status && val.status < 300) {
            setInfoMessage("User updated");
          } else {
            if (val.status === 401) {
              navigator("/login");
            }
            if (val.data && val.data.form && val.data.form.errors) {
              setErrorMessage(
                val.data.form.errors[Object.keys(val.data.form.errors)[0]]
              );
            }
          }
        });
      });

      // create/delete backgrounds
      selectedBackgrounds.forEach((background) => {
        if (background.pk === 0) {
          //create background_user
          const formDataBackgroundUser = new FormData();
          formDataBackgroundUser.append("user", user_pk[1]);
          Post(
            createBackgroundUserConnection(background.avatar_background.pk),
            formDataBackgroundUser
          ).then((val) => {
            if (val.status && val.status < 300) {
              setInfoMessage("User updated");
            } else {
              if (val.status === 401) {
                navigator("/login");
              }
              if (val.data && val.data.form && val.data.form.errors) {
                setErrorMessage(
                  val.data.form.errors[Object.keys(val.data.form.errors)[0]]
                );
              }
            }
          });
        } else {
          //do nothing cause emote_user already created
        }
      });
      deleteBackgrounds.forEach((background) => {
        // delete background_user
        Post(removeBackgroundUserConnection(background.pk), emptyFormData).then(
          (val) => {
            if (val.status && val.status < 300) {
              setInfoMessage("User updated");
            } else {
              if (val.status === 401) {
                navigator("/login");
              }
              if (val.data && val.data.form && val.data.form.errors) {
                setErrorMessage(
                  val.data.form.errors[Object.keys(val.data.form.errors)[0]]
                );
              }
            }
          }
        );
      });
    }
  }

  return !isLoading ? (
    <div className="edit-user">
      {infoMessage !== "" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {infoMessage}
        </Alert>
      )}
      {errorMessage !== "" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}
      <Topbar
        searchbar={false}
        title={`Edit User: ${userInfo.username}`}
        buttonTitle="Save User"
        handleButton={() => handleSave()}
      />

      <Grid container spacing={2} margin="0 .5rem" width="calc(100% - 1rem)">
        <Grid item xs={6}>
          <TextField
            name="username"
            label="Username"
            fullWidth
            margin="normal"
            value={userInfo.username}
            onChange={handleChange}
            error={usernameError !== ""}
            helperText={usernameError}
            disabled={isLoading}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="email"
            label="Email"
            fullWidth
            margin="normal"
            value={userInfo.email}
            onChange={handleChange}
            error={emailError !== ""}
            helperText={emailError}
            disabled={isLoading}
          />
        </Grid>

        <Grid item xs={6}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="is_organization_manager"
                  checked={userInfo.is_organization_manager}
                  onChange={handleCheck}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Organization Manager"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="is_user_manager"
                  checked={userInfo.is_user_manager}
                  onChange={handleCheck}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="User Manager"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="is_active"
                  checked={userInfo.is_active}
                  onChange={handleCheck}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Active"
            />
          </FormGroup>
        </Grid>

        <Grid container item xs={12} spacing={2}>
          <Grid container item md={6} sm={12}>
            <Grid item xs={12}>
              <Typography>Avatars</Typography>
            </Grid>
            <Grid
              className="avatars"
              container
              item
              xs={12}
              maxHeight="25rem"
              overflow="auto"
              paddingY="1rem"
            >
              {avatars.map((avatar) => {
                return (
                  <Grid item md={4} sm={6} xs={12}>
                    <SelectableButton
                      selected={selectedAvatars.some(
                        (cur) => cur.avatar.pk === avatar.pk
                      )}
                      onClick={() => {
                        handleSelectAvatars(avatar);
                      }}
                    >
                      <Box
                        component={"img"}
                        sx={{
                          width: "100%",
                        }}
                        alt={`${avatar.name} avatar`}
                        src={avatar.image}
                      />
                    </SelectableButton>
                    <Typography textAlign='center' style={{ marginTop: '-.4rem' }}>{avatar.name}</Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>

          <Grid container item md={6} sm={12}>
            <Grid item xs={12}>
              <Typography>Emotes</Typography>
            </Grid>
            <Grid
              className="avatars"
              container
              item
              xs={12}
              maxHeight="25rem"
              overflow="auto"
              paddingY="1rem"
            >
              {emotes.map((emote) => {
                return (
                  <Grid item md={4} sm={6} xs={12}>
                    <SelectableButton
                      selected={selectedEmotes.some(
                        (cur) => cur.emote.pk === emote.pk
                      )}
                      onClick={() => {
                        handleSelectEmotes(emote);
                      }}
                    >
                      <Box
                        component={"img"}
                        sx={{
                          width: "100%",
                        }}
                        alt={`${emote.name} emote`}
                        src={emote.thumbnail_image}
                      />
                    </SelectableButton>
                    <Typography textAlign='center' style={{ marginTop: '-.4rem' }}>{emote.name}</Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>

          <Grid container item md={6} sm={12}>
            <Grid item xs={12}>
              <Typography>Backgrounds</Typography>
            </Grid>
            <Grid
              className="avatars"
              container
              item
              xs={12}
              maxHeight="25rem"
              overflow="auto"
              paddingY="1rem"
            >
              {backgrounds.map((background) => {
                return (
                  <Grid item md={4} sm={6} xs={12}>
                    <SelectableButton
                      selected={selectedBackgrounds.some(
                        (cur) => cur.avatar_background.pk === background.pk
                      )}
                      onClick={() => {
                        handleSelectBackgrounds(background);
                      }}
                    >
                      <Box
                        component={"img"}
                        sx={{
                          width: "100%",
                        }}
                        alt={`${background.name} background`}
                        src={background.image}
                      />
                    </SelectableButton>
                    <Typography textAlign='center' style={{ marginTop: '-.4rem' }}>{background.name}</Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  ) : (
    <div>Loading...</div>
  );
}
